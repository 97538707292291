import React, { useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
import axios from "axios";

const email_card = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "8rem"
}

const title_h5 = {
    fontSize: "26px",
    fontWeight: "bold",
    textAlign: "center"
}
const card_text = {
    fontSize: "20px",
    fontWeight: "500",
    textAlign: "center"
}
const wait_card = {
    fontSize: "28px",
    fontWeight: "600",
    textAlign: "center"
}
const VerifyEmail = () => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [emailMassage, setEmailMassage] = useState("");
    useEffect(() => {
        setIsLoading(true)
        let temp_token = window.location.href?.split("/")[4]
        let header = {
            headers: {
                "Content-Type": 'application/json',
                Authorization: "Bearer " + temp_token,
            },
        };
        let body = {
            token: temp_token,

        };
        axios
            .post(
                `${process.env.REACT_APP_APIURL}v1/user/verify-email`,
                body,
                header
            )
            .then(function (res) {
                let response = res.data;
                if (response.message === "Email verified successfully") {
                    setEmailMassage("Your Email is successfully verified.")
                    setIsSuccess(true)
                } else {
                    setEmailMassage(response.message)
                    setIsSuccess(false)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                console.log("error", err)
            });
    }, []);

    return (
        <div style={email_card}>
            {isLoading ? (
                <>
                    <div>
                        <img src={"/assets/images/loading.gif"} alt="img" style={{ width: "250px", marginLeft: '5rem' }} />
                        <h4 style={wait_card}>Please wait...</h4>
                    </div>
                </>
            ) : (
                <Card style={{ width: '25rem' }}>
                    {isSuccess && (
                        <img src={"/assets/images/success.gif"} alt="img" style={{ width: "250px", marginLeft: '5rem' }} />
                    )}
                    <Card.Body>
                        <Card.Title style={title_h5}>{isSuccess ? "Congratulations!!!" : "Failed"}</Card.Title>
                        <Card.Text style={card_text}>
                            {isSuccess ? emailMassage : "Your Email is not verified."}
                        </Card.Text>
                    </Card.Body>
                </Card>
            )}
        </div>
    )
}

export default VerifyEmail;