import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import Integration from "../Components/Integration Components/Integration";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  createUserIntegration,
  disconnectIntegration,
  getUserIntegration,
  getLeadList,
  updateUserIntegration,
} from "../constants/ApiCall";
import moment from "moment";
import { makeStyles } from "@material-ui/core";

import Button from "../Components/Integration Components/Button";
import Header from "../Components/Integration Components/Header";
import Logo from "../images/indiamart.png";

function Indiamart(props) {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [errorText, setErrorText] = useState();
  const [integration, setIntegration] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [integrationDate, setIntegrationDate] = useState(new Date());
  const [selectedLeadList, setSelectedLeadList] = useState("");

  useEffect(() => {
    getIntegration();
  }, []);

  const getIntegration = async () => {
    const response = await getUserIntegration();
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "indiamart"
      );
      getLeadListData();
      if (_integration) {
        setIntegration(_integration);
        setIntegrationDetails(_integration.details[0]);
        setData({
          glusrMobileKey: _integration.details[0]?.glusrMobileKey || "",
          glusrMobile: _integration.details[0]?.glusrMobile || "",
        });
        setSelectedLeadList(_integration.details[0]?.list);
        setIntegrationDate(_integration.createdAt);
      }
    }
  };

  const getLeadListData = async () => {
    let params = {
      isAscending: false,
      page: 1,
      perPage: 15,
    };
    const response = await getLeadList(params);
    if (response && response.status) {
      setLeadData(response.data);
    } else {
      setLeadData([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingText("Connecting...");

    const payload = {
      // glusrMobile: "9898989898", //data?.glusrMobile,
      // version: 1,
      glusrMobileKey: data?.glusrMobileKey,
      ...(selectedLeadList != "" && {
        list: selectedLeadList,
      }),
    };
    const response = await createUserIntegration("indiamart", [payload]);
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/integration/success`,
      });
    } else {
      setErrorText(response ? response.message : "Error in integration");
    }
  };

  const handleDisconnectIntegration = async () => {
    setLoading(true);
    setLoadingText("Disconnecting...");
    const response = await disconnectIntegration(
      integration?.userIntegrationId
    );
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/disconnect/success`,
      });
    } else {
      NotificationManager.error("Error in integration");
    }
  };
  const handleSelectLeadList = async (lead_id) => {
    setSelectedLeadList(lead_id);
    if (integration) {
      let tempObj = {};
      if (lead_id) {
        tempObj = {
          ...integrationDetails,
          list: lead_id,
        };
      } else {
        tempObj = {
          ...integrationDetails,
        };
      }
      const response = await updateUserIntegration(
        integration?.userIntegrationId,
        tempObj
      );
      if (response && response.status) {
        NotificationManager.success(response.message);
      } else {
        NotificationManager.error("Error in integration");
      }
    }
  };

  const useStyles = makeStyles(() => ({
    container: {
      padding: integration ? "0 24px" : "0 25px",
      "& button": {
        width: "330px",
        position: "relative",
        transform: "translateX(-50%)",
        left: "50%",
        marginBottom: "30px !important",
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
      margin: integration ? "0 10px" : "0",
    },
    iframe: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "20px",
      borderRadius: "10px",
    },
    selectWrap: {
      width: "fit-content",
      height: "40px",
      width: "100%",
      position: "relative",
      "& svg": {
        position: "absolute",
        right: "22px",
        top: "16.5px",
      },
    },
    select: {
      height: "40px",
      padding: "0 15px",
      color: "rgba(0, 0, 0, 0.5)",
      fontWeight: "600",
      appearance: "none",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
    },
    input: {
      width: "100%",
      minWidth: "331px",
      height: "39px",
      background: "#FFFFFF",
      borderRadius: "10px",
      border: "none",
      padding: "10px 17px",
      "&::placeholder": {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        color: "rgba(0, 0, 0, 0.5)",
      },
    },
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"Indiamart integration"}
            logo={<img src={Logo} alt="Indiamart" width="40" height="40" />}
          />
          <div className={classes.text}>
            Integrate your Indiamart account to automatically import inquiries,
            including direct inquiries, consumed buy leads, and calls.
          </div>
          <div className={classes.text + " mt-4"}>
            Please note that it may take up to 24 hours for your leads to start
            syncing after integration.
          </div>
          {!integration && (
            <div className={classes.iframe + " iframe_container"}>
              <iframe
                width="300"
                height="150"
                frameBorder="0"
                borderRadius="5"
                title="youtube"
                style={{ borderRadius: "10px" }}
                src="https://www.youtube.com/embed/kyEfK7fw0a0?controls=0"
              ></iframe>
            </div>
          )}
          {/* <div className="mt-4 form_lbl">Indiamart Mobile Number</div>
            <input
              type="text"
              style={inputStyle}
              className="mt-2"
              name="glusrMobile"
              onChange={handleChange}
              value={data?.glusrMobile}
            /> */}
          <div className="mt-4 form_lbl">Indiamart CRM Key</div>
          <input
            type="text"
            //style={inputStyle}
            className={"mt-1 " + classes.input}
            name="glusrMobileKey"
            onChange={handleChange}
            value={data?.glusrMobileKey}
            placeholder="Enter Key"
          />
          <p className="error-text">{errorText ? errorText : null}</p>
          <div className="mt-4 form_lbl">Select Custom lead list</div>
          <label className={classes.selectWrap}>
            <select
              value={selectedLeadList}
              onChange={(e) => handleSelectLeadList(e.target.value)}
              className={classes.select}
            >
              <option value={""}>Default lead</option>
              {leadData.map((item, i) => {
                return (
                  <option key={i} value={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.41 0L6 4.59L10.59 0L12 1.42L6 7.42L0 1.42L1.41 0Z"
                fill="black"
              />
            </svg>
          </label>
          {integration && (
            <div className={"mt-4 " + classes.text}>
              Indiamart Connected successfully on{" "}
              {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
            </div>
          )}

          {integration ? (
            <Button onClick={handleDisconnectIntegration}>Disconnect</Button>
          ) : (
            <Button onClick={onSubmit}>Connect</Button>
          )}
        </div>
      </LoadingOverlay>
      <NotificationContainer />
    </BottomSheet>
  );
}

export default Indiamart;
