import React, { useState, useRef } from "react";
import LoadingOverlay from "react-loading-overlay";

// MATERIAL COMP IMPORT
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Alert from "@material-ui/lab/Alert";

// COMPONENT IMPORT
import Header from "../Components/Header";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function Website(props) {
  // STATE

  const [isActive, setIsActive] = useState(true);
  const [loadingText, setLoadingText] = useState(null);
  const [showAlert, toggleAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);

  const classes = useStyles();

  return (
    <LoadingOverlay active={isActive} spinner text={loadingText}>
      {!isActive && <Header />}
      <div className={classes.root}>
        {showAlert && <Alert severity={alertType}>{alertMessage}</Alert>}
        <CssBaseline />
        <Container component="main" className={classes.main} maxWidth="sm">
          {!isActive && (
            <>
              <Typography variant="h5" component="h5" gutterBottom>
                Website Integration Steps
              </Typography>
              <Typography variant="body1" component="h2" gutterBottom>
                {"1. Copy generated script and paste it on your web page"}
              </Typography>
              <Grid container spacing={3}></Grid>
            </>
          )}
        </Container>
      </div>
    </LoadingOverlay>
  );
}

export default Website;
