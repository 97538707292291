/* Libraries */
import axios from "axios";
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const LOCAL_API_URL = process.env.REACT_APP_LOCAL_API_URL;
const DEV_API_URL = process.env.REACT_APP_DEV_API_URL;
const PROD_API_URL = process.env.REACT_APP_PROD_API_URL;
var API_URL = LOCAL_API_URL;

if (ENVIRONMENT === "prod") {
  API_URL = PROD_API_URL;
} else if (ENVIRONMENT === "dev") {
  API_URL = DEV_API_URL;
}
const instance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
});
const setJwt = async (config) => {
  const url = new URL(window.location.href);
  const parameterValue = localStorage.getItem("auth_token");

  // console.log("5555>>", get(window.location.href, "token"));
  // console.log(parameterValue);

  let token = get(window.location.href, "token");

  if (token || parameterValue) {
    if (token) {
      config.headers["Authorization"] = `Bearer ${token || ""}`;
    } else {
      config.headers["Authorization"] = `${parameterValue || ""}`;
    }

    return Promise.resolve(config);
  }
  return Promise.resolve(config);
};

instance.interceptors.request.use(
  (config) => setJwt(config),
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
function get(url, n) {
  var half = url.split(n + "=")[1];
  return half !== undefined ? decodeURIComponent(half.split("&")[0]) : null;
}
