import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  createUserIntegration,
  disconnectIntegration,
  getUserIntegration,
  getLeadList,
  updateUserIntegration,
} from "../constants/ApiCall";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import moment from "moment";

import Header from "../Components/Integration Components/Header";
import Integration from "../Components/Integration Components/Integration";
import Button from "../Components/Integration Components/Button";

import Logo from "../images/zapier.png";
import { makeStyles } from "@material-ui/core";

function Zipier(props) {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [errorText, setErrorText] = useState();
  const [copied, setCopied] = useState(false);
  const [integration, setIntegration] = useState(null);
  const [leadData, setLeadData] = useState([]);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [integrationDate, setIntegrationDate] = useState(new Date());

  useEffect(() => {
    connectZapier();
  }, []);

  const connectZapier = async () => {
    setLoading(true);
    setLoadingText("Connecting...");

    const response = await createUserIntegration("zapier");
    setLoading(false);
    if (response && response.status) {
      getIntegration();
    } else {
      setErrorText(response ? response.message : "Error in integration");
    }
  };

  const getIntegration = async () => {
    const response = await getUserIntegration();
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "zapier"
      );
      if (_integration) {
        getLeadListData();
        setIntegration(_integration);
        setIntegrationDate(_integration.createdAt);
        setIntegrationDetails(_integration.details);
        setData({ apiKey: _integration.details.apiKey || "" });
      }
    }
  };

  const getLeadListData = async () => {
    let params = {
      isAscending: false,
      page: 1,
      perPage: 15,
    };
    const response = await getLeadList(params);
    if (response && response.status) {
      setLeadData(response.data);
    } else {
      setLeadData([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleDisconnectIntegration = async () => {
    setLoading(true);
    setLoadingText("Disconnecting...");
    const response = await disconnectIntegration(
      integration?.userIntegrationId
    );
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/disconnect/success`,
      });
    } else {
      alert("Error  in integration");
    }
  };
  const handleSelectLeadList = async (lead_id) => {
    let tempObj = {};
    if (lead_id) {
      tempObj = {
        ...integrationDetails,
        list: lead_id,
      };
    } else {
      tempObj = {
        ...integrationDetails,
      };
    }

    const response = await updateUserIntegration(
      integration?.userIntegrationId,
      tempObj
    );
    if (response && response.status) {
      NotificationManager.success(response.message);
    } else {
      NotificationManager.error("Error in integration");
    }
  };
  const useStyles = makeStyles(() => ({
    container: {
      padding: "0 19px",
      "& .form_lbl": {
        fontFamily: "'Gilroy-SemiBold' !important",
      },
      "& button": {
        position: "relative",
        transform: "translateX(-50%)",
        left: "50%",
        width: "330px",
        marginBottom: "30px !important",
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
    },
    iframe: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "20px",
      borderRadius: "10px",
    },
    selectWrap: {
      width: "fit-content",
      height: "40px",
      width: "100%",
      position: "relative",
      "& svg": {
        position: "absolute",
        right: "22px",
        top: "16.5px",
      },
    },
    select: {
      height: "40px",
      padding: "0 15px",
      color: "rgba(0, 0, 0, 0.5)",
      fontWeight: "600",
      appearance: "none",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
    },
    input: {
      width: "100%",
      minWidth: "331px",
      height: "39px",
      background: "#FFFFFF",
      borderRadius: "10px",
      border: "none",
      padding: "10px 45px 10px 17px !important",
      "&::placeholder": {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        color: "rgba(0, 0, 0, 0.5)",
      },
    },
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"Zapier integration"}
            logo={
              <img
                src={Logo}
                alt="Zapier"
                width="24"
                height="24"
                style={{ borderRadius: "50%" }}
              />
            }
          />
          <div className={classes.text}>
            Connect your Zapier account to 3sigma and run your workflows with
            3000+ apps
          </div>
          <div className={classes.text + " mt-5 ms-4"}>
            How does Zapier integration work?
          </div>
          <div className={classes.iframe + " iframe_container"}>
            <iframe
              width="300"
              height="150"
              borderRadius="5"
              title="youtube"
              style={{ borderRadius: "10px" }}
              src="https://www.youtube.com/embed/-3M5OMIEELM?controls=0"
            ></iframe>
          </div>
          {integration && (
            <div className={"pt-3 mt-2 " + classes.text}>
              Zapier Connected successfully on{" "}
              {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
            </div>
          )}
          <div className="mt-4 form_lbl">API key</div>
          <div className="copyIcon d-flex">
            <input
              type="text"
              name="apiKey"
              className={"mt-2 mb-2 " + classes.input}
              onChange={handleChange}
              value={data?.apiKey}
            />
            <CopyToClipboard text={data?.apiKey} onCopy={() => setCopied(true)}>
              {copied ? (
                <img
                  src={window.location.origin + "/assets/images/checked.ico"}
                  alt="copy-icon"
                  className="success_tick"
                  style={{marginLeft: "-30px", marginTop: "0px"}}
                />
              ) : (
                <img
                  src={window.location.origin + "/assets/images/copy.png"}
                  alt="copy-icon"
                  style={{ float: "right", marginLeft: "-30px" }}
                />
              )}
            </CopyToClipboard>
          </div>
          <div className="mt-4 form_lbl">Select Custom lead list</div>
          <label className={classes.selectWrap + " mb-5"}>
            <select
              onChange={(e) => handleSelectLeadList(e.target.value)}
              className={classes.select + " mb-5"}
            >
              <option value={""} disabled selected>
                Select list{" "}
              </option>
              {leadData.map((item, i) => {
                return (
                  <option key={i} value={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.41 0L6 4.59L10.59 0L12 1.42L6 7.42L0 1.42L1.41 0Z"
                fill="black"
              />
            </svg>
          </label>
          {integration ? (
            <Button
              onClick={handleDisconnectIntegration}
              
              
            >
              Disconnect
            </Button>
          ) : (
            <Button
              onClick={connectZapier}
                
               
            >
              Connect
            </Button>
          )}
        </div>
      </LoadingOverlay>
      <NotificationContainer />
    </BottomSheet>
  );
}

export default Zipier;
