import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

// APP JS IMPORT
import App from './App';


// SERVICE WORKER
import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleClientService } from './constants/Google_client_service';

const muiTheme = createTheme({
    palette: {
      primary: {
        main: 'rgb(0, 160, 220)',
      }
    },
  });

ReactDOM.hydrate(<ThemeProvider theme={muiTheme}><GoogleOAuthProvider clientId={GoogleClientService.web.client_id}><BrowserRouter><App /></BrowserRouter></GoogleOAuthProvider></ThemeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
