import React, { Component, useEffect, useState, useRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import axios from "axios";

import Integration from "../Components/Integration Components/Integration";
const bottomBtn = {
  backgroundColor: "#3A4B86",
  padding: "10px",
  color: "#FFFFFF",
  borderRadius: "15px",
  textAlign: "center",
  width: "92%",
  fontWeight: "500",
  fontSize: "20px",
  justifyContent: "center",
  alignItems: "center",
  bottom: "20px",
};

const inputStyle = {
  borderRadius: "10px",
  // backgroundColor: "lightgray",
  height: "40px",
  width: "360px",
  border: "none",
  outline: "none",
};

const iframeStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "20px",
  borderRadius: "10px",
};

const roundIcon = {
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px 10px",
  borderRadius: "50%",
};

const copyIcon = {
  backgroundColor: "white",
  alignItems: "center",
  borderRadius: "10px",
  paddingRight: "10px",
};
function Typeform(props) {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const onSubmit = (e) => {
    let header = {
      headers: {
        Authorization: "Bearer " + props?.user_id,
      },
    };
    let body = {
      integrationKey: "typeform",
      details: {
        key: data?.url,
        userid: "123",
        answers: "123",
        profile_id: "123",
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_APIURL}v1/integration/user-integration`,
        body,
        header
      )
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => console.log("error", err));
  };

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 2]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <div className="container">
          <Integration
            title={"Typeform Integration"}
            logo={window.location.origin + "/assets/images/typeform.png"}
            isRounded={true}
          />
          <div className="px-3 py-2">
            <div>
              Connect your Typeform account to 3sigma and import your Form
              submissions automatically
            </div>
            <div className="mt-4">How does Typeform integration work?</div>
            {/* <textarea rows="3" cols="50" style={inputStyle} className="mt-2" /> */}
            <div style={iframeStyle}>
              <iframe
                width="300"
                height="150"
                frameBorder="0"
                borderRadius="5"
                style={{ borderRadius: "10px" }}
                src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0"
              ></iframe>
            </div>
            <div className="mt-4">URL</div>
            <div style={copyIcon}>
              <input
                type="text"
                style={inputStyle}
                className="mt-2"
                name="url"
                onChange={handleChange}
                value={data?.url}
              />
              <img src={window.location.origin + "/assets/images/copy.png"} />
            </div>

            <div style={bottomBtn} onClick={onSubmit} className="btn">
              <span style={roundIcon}>
                <img
                  src={window.location.origin + "/assets/images/typeform.png"}
                />
              </span>
              &nbsp;Connect Typeform
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </BottomSheet>
  );
}

export default Typeform;
