import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { uploadFiles } from "../constants/ApiCall";

class OrderPdfService {
    static async exportPDF(element, data) {
        try {
            console.log("1. Starting PDF generation");
            
            // Initialize PDF
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
                compress: true
            });

            console.log("2. Created PDF instance");

            // Create a deep clone of the element
            const clone = element.cloneNode(true);
            
            // Prepare container
            const container = document.createElement('div');
            container.style.position = 'fixed';  // Changed from absolute
            container.style.top = '0';
            container.style.left = '-9999px';
            container.style.width = '210mm';
            container.style.height = 'auto';
            container.style.background = 'white';
            
            // Add clone to container
            container.appendChild(clone);
            
            // Add container to document
            document.body.appendChild(container);

            console.log("3. Container prepared and added to document");

            try {
                // Add necessary styles
                const styleElement = document.createElement('style');
                styleElement.textContent = `
                    .pdf-content {
                        background: white !important;
                        width: 210mm !important;
                        padding: 10mm !important;
                        color: black !important;
                    }
                    .pdf-content * {
                        -webkit-print-color-adjust: exact !important;
                        color-adjust: exact !important;
                    }
                    table { break-inside: avoid !important; }
                    .calculations { break-inside: avoid !important; }
                    .bank-details, .terms-conditions { break-inside: avoid !important; }
                `;
                container.appendChild(styleElement);

                // Wait for content to be properly rendered
                await new Promise(resolve => setTimeout(resolve, 500));

                console.log("4. Starting canvas generation");

                // Generate canvas with improved settings
                const canvas = await html2canvas(clone, {
                    scale: 2,
                    useCORS: true,
                    allowTaint: true,
                    backgroundColor: '#ffffff',
                    logging: true,
                    onclone: (clonedDoc) => {
                        console.log("5. Cloning document for canvas");
                        const content = clonedDoc.querySelector('.pdf-content');
                        if (content) {
                            // Enhance contrast
                            content.style.color = '#000000';
                            
                            // Ensure all sections are visible
                            const sections = content.children;
                            Array.from(sections).forEach(section => {
                                section.style.display = 'block';
                                section.style.visibility = 'visible';
                            });
                        }
                    }
                });

                console.log("6. Canvas generated successfully", {
                    width: canvas.width,
                    height: canvas.height
                });

                // Calculate dimensions
                const imgWidth = 210; // A4 width
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                const pageHeight = 297; // A4 height
                let heightLeft = imgHeight;
                let position = 0;

                // Add first page
                pdf.addImage(
                    canvas.toDataURL('image/jpeg', 1.0),
                    'JPEG',
                    0,
                    position,
                    imgWidth,
                    imgHeight,
                    undefined,
                    'FAST'
                );
                heightLeft -= pageHeight;

                console.log("7. First page added to PDF");

                // Add additional pages if needed
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(
                        canvas.toDataURL('image/jpeg', 1.0),
                        'JPEG',
                        0,
                        position,
                        imgWidth,
                        imgHeight,
                        undefined,
                        'FAST'
                    );
                    heightLeft -= pageHeight;
                    console.log("8. Additional page added to PDF");
                }

                console.log("9. Converting PDF to blob for upload");

                // Get the PDF as blob
                const pdfBlob = pdf.output('blob');
                
                // Create file from blob
                const pdfFile = new File(
                    [pdfBlob],
                    `Order_${data._id}.pdf`,
                    { type: 'application/pdf' }
                );

                console.log("10. Creating FormData for upload");

                // Create FormData
                const formData = new FormData();
                formData.append('order', pdfFile);
                formData.append('type', 'order');
                formData.append('order', data._id);

                // Get token from URL
                const url = new URL(window.location.href);
                const token = url.searchParams.get("token");

                console.log("11. Starting file upload");

                // Upload file
                await uploadFiles(formData);

                console.log("12. File uploaded successfully");

                // Handle React Native WebView if present
                if (window?.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage("File Uploaded");
                }

                return true;
            } finally {
                // Cleanup
                if (container && container.parentNode) {
                    container.parentNode.removeChild(container);
                }
                console.log("13. Cleanup completed");
            }
        } catch (error) {
            console.error('PDF generation or upload failed:', error);
            console.error('Error details:', {
                message: error.message,
                stack: error.stack
            });
            throw error;
        }
    }
}

export default OrderPdfService;