import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: "22px 15px",
    marginBottom: "14px",
    fontFamily: "Gilroy-Medium",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#000000",
    display: "flex",
    alignItems: "center",
    gap: "12px"
  },
}));

export default function Header({ setOpen }) {
  const classes = useStyles();

  return (
    <Container component="header" className={classes.header} maxWidth="sm">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => setOpen(false)}
      >
        <path
          d="M15.84 6.92V8.92H3.84L9.34 14.42L7.92 15.84L0 7.92L7.92 0L9.34 1.42L3.84 6.92H15.84Z"
          fill="black"
        />
      </svg>
      Manage integration
    </Container>
  );
}
