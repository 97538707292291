import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
// import { BottomSheet } from "react-spring-bottom-sheet";
import axios from "axios";
import Integration from "../Components/Integration Components/Integration";
import {
  getUserIntegration,
  getLeadList,
  updateUserIntegration,
  disconnectIntegration
} from "../constants/ApiCall";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from "moment";

const bottomBtn = {
  backgroundColor: "#3A4B86",
  padding: "10px",
  color: "#FFFFFF",
  borderRadius: "15px",
  textAlign: "center",
  width: "92%",
  fontWeight: "700",
  fontSize: "20px",
  justifyContent: "center",
  alignItems: "center",
  bottom: "20px",
};

const roundIcon = {
  backgroundColor: "white",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px 10px",
  borderRadius: "50%",
};

const sheet_List = {
  display: 'grid'
}
const fb_font1 = {
  fontWeight: "bold",
  fontSize: "18px",
}
function GoogleSheets(props) {
  const temp_token = window.location.href?.split("=")[1]
  // const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [sheetList, setSheetList] = useState([]);
  const [integration, setIntegration] = useState(null);
  const [leadData, setLeadData] = useState([]);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [integrationDate, setIntegrationDate] = useState(new Date());
  const [selectedLeadList, setSelectedLeadList] = useState("");

  useEffect(() => {
    getIntegration();
  }, []);

  const getIntegration = async () => {
    const response = await getUserIntegration();
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "google_spread_sheets"
      );
      getLeadListData()
      if (_integration) {
        setIntegration(_integration);
        setIntegrationDate(_integration.createdAt)
        setIntegrationDetails(_integration.details)
      }
    }
  };
  const getLeadListData = async () => {
    let params = {
      isAscending: false,
      page: 1,
      perPage: 15,
    };
    const response = await getLeadList(params);
    if (response && response.status) {
      setLeadData(response.data)
    } else {
      setLeadData([])
    }
  }

  const responseSuccess = async (response) => {
    let header = {
      headers: {
        "Content-Type": 'application/json',
        Authorization: "Bearer " + temp_token,
      },
    };
    let body = {
      tokens: {
        access_token: response?.access_token,
        scope: response?.scope,
        token_type: response?.token_type,
        id_token: response?.id_token,
        expiry_date: response?.expires_at,
      },
    };
    await axios
      .post(
        `${process.env.REACT_APP_WEB_APIURL}v1/user/get-google-tokens`,
        body,
        header
      )
      .then((res) => {
        if (res.data.status) {
          window.location.reload()
        }
      })
      .catch((err) => console.log("error", err));
  }

  const handleSelectSheet = (id, check) => {
    let tempArray = [...sheetList]
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].spreadsheetId === id) {
        if (check === "yes") {
          tempArray[i].isYes = true
          tempArray[i].isNo = false
        } else if (check === "no") {
          tempArray[i].isYes = false
          tempArray[i].isNo = true
        }
        if (check === "isSelected") {
          tempArray[i].isSelected = tempArray[i].isSelected ? false : true
        }
        tempArray[i].isRecursive = check === "yes" ? true : false
      }
    }
    setSheetList(tempArray);
  }
  window.$(document).ready(function () {
    window.$('#googleSheet').modal({
      backdrop: 'static',
      keyboard: false
    })
  })
  const SubmitGoogleSheetData = () => {
    let header = {
      headers: {
        "Content-Type": 'application/json',
        Authorization: "Bearer " + temp_token,
      },
    };
    let tempArray = []
    for (let i = 0; i < sheetList.length; i++) {
      if (sheetList[i].isRecursive) {
        let tempObj = {
          spreadsheetId: sheetList[i].spreadsheetId,
          spreadsheetName: sheetList[i].spreadsheetName,
          isRecursive: sheetList[i].isRecursive
        }
        tempArray.push(tempObj)
      }
    }
    let body = {
      integrationKey: 'google_spread_sheets',
      details: tempArray
    };
    axios
      .post(
        `${process.env.REACT_APP_WEB_APIURL}v1/integration/user-integration`,
        body,
        header
      )
      .then(function (res) {
        let response = res.data;
        if (response.status) {
          NotificationManager.success(response.message);
          window.$('#googleSheet').modal("hide");
        }
      })
      .catch((err) => {
        console.log("error", err)
      });
  }
  useEffect(() => {
    const handleClientLoad = () => {
      // Load the Google API client library
      window.gapi.load('client:auth2', initClient);
    };

    const initClient = () => {
      // Initialize the Google API client
      window.gapi.client.init({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        clientId: process.env.REACT_APP_CLIENT_ID_KEY,
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
        scope: "https://www.googleapis.com/auth/drive"
      }).then(() => {
        // Listen for sign-in state changes
        window.gapi.auth2.getAuthInstance().isSignedIn.listen(handleSignInChange);

        // Handle initial sign-in state
        handleSignInChange(window.gapi.auth2.getAuthInstance().isSignedIn.get());
      }).catch((error) => {
        console.error('Failed to initialize Google API client:', error);
      });
    };

    const handleSignInChange = (isSignedIn) => {
      if (isSignedIn) {
        fetchGoogleSheets();
      } else {
        // User is not signed in
      }
    };

    const fetchGoogleSheets = () => {
      // Use the Google API client to fetch Google Sheets
      window.gapi.client.drive.files
        .list({
        })
        .then((response) => {
          const tempSheetList = response.result.files;
          let tempArray = []
          for (let i = 0; i < tempSheetList.length; i++) {
            if (tempSheetList[i].mimeType === "application/vnd.google-apps.spreadsheet") {
              let tempObj = {
                spreadsheetId: tempSheetList[i].id,
                spreadsheetName: tempSheetList[i].name,
                isRecursive: false,
                isYes: false,
                isNo: false,
                isSelected: false
              }
              tempArray.push(tempObj)
            }
          }
          setSheetList(tempArray)
          document.getElementById("modalClick").click()
        })
        .catch((error) => {
          console.error('Failed to fetch Google Sheets:', error);
        });
    };
    // Load the Google API client library on component mount
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = handleClientLoad;
    document.body.appendChild(script);

    return () => {
      // Clean up the script tag on component unmount
      document.body.removeChild(script);
    };
  }, []);

  const handleSignInClick = () => {
    // Trigger Google Sign-In
    window.gapi.auth2.getAuthInstance().signIn().then(function (response) {
      responseSuccess(response.zc)
    });
  };

  const handleSelectLeadList = (id) => {
    handleUpdateUserIntegration(id)
  }

  const handleUpdateUserIntegration = async (lead_id) => {
    setSelectedLeadList(lead_id)
    if (integration) {
      let tempObj = {}
      if (lead_id) {
        tempObj = {
          ...integrationDetails,
          list: lead_id,
        }
      } else {
        tempObj = {
          ...integrationDetails,
        }
      }
      const response = await updateUserIntegration(integration?.userIntegrationId, tempObj);
      if (response && response.status) {
        NotificationManager.success(response.message);
      } else {
        NotificationManager.error("Error in integration");
      }
    }
  };
  const handleDisconnectIntegration = async () => {
    setLoading(true);
    setLoadingText("Disconnecting...");
    const response = await disconnectIntegration(
      integration?.integrationId
    );
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/disconnect/success`,
      });
    } else {
      alert("Error in integration");
    }
  };
  return (
    <>
      {/* <BottomSheet
        open={open}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 2]}
        defaultSnap={({ lastSnap, snapPoints }) =>
          lastSnap ?? Math.max(...snapPoints)
        }
      > */}
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <div className="container">
          <Integration
            title={"Google sheet"}
            logo={window.location.origin + "/assets/images/GoogleSheet.png"}
            isRounded={true}
          />
          <div className="px-3 py-2">
            <div className="font_light">
              Easily import data  from Google sheets. You can also import data on recuring basis .
            </div>
            <div className="mt-4 font_bold" style={{ marginTop: "10rem" }}>How does Google sheet integration works?</div>
            {integration && (
              <div className="pt-5" style={fb_font1}>
                Google sheets Connected successfully on {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
              </div>
            )}
            {/* {integration ? (
              <div style={bottomBtn} onClick={handleDisconnectIntegration} className="btn">
                <span style={roundIcon}>
                  <img
                    src={window.location.origin + "/assets/images/GoogleSheet.png"}
                  />
                </span>
                &nbsp;Disconnect Google Sheets
              </div>
            ) : ( */}
            <div style={bottomBtn} onClick={handleSignInClick} className="btn">
              <span style={roundIcon}>
                <img
                  src={window.location.origin + "/assets/images/GoogleSheet.png"}
                />
              </span>
              &nbsp;Connect Google Sheets
            </div>
            {/* )} */}

          </div>
        </div>
        <button
          style={{ display: "none" }}
          type="button"
          id="modalClick"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#googleSheet"
        />

      </LoadingOverlay>
      <div className="modal" id="googleSheet">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Google Sheets</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <div style={sheet_List} className="form_sheet_body">
                <div className="row">

                  <div className="col-md-12">
                    {sheetList?.length > 0 ? (
                      <>
                        <label className="modal_lbl">Spreadsheet name</label>
                        <label className="modal_lbl" style={{ float: 'right', paddingRight: '20px' }}>Import new rows automatically?</label>
                        {sheetList?.map((item, i) => {
                          return (
                            <div className="sheet_main_dv" key={i}>
                              <div key={i} className="sheet_check_tick">
                                <input
                                  type="checkbox"
                                  id={i}
                                  name="isSelected"
                                  value="isSelected"
                                  checked={item.isSelected}
                                  onChange={() => handleSelectSheet(item.spreadsheetId, "isSelected")} />

                                <label style={{ margin: "12px 10px", fontWeight: "600" }} title={item.spreadsheetName}> {item.spreadsheetName.length > 30 ? item.spreadsheetName.substr(0, 30).concat("...") : item.spreadsheetName}</label>
                              </div>
                              <div className={item.isSelected ? "disShow" : "disNone"}>
                                <label className="pr_3" htmlFor={i}> Yes</label>
                                <input
                                  className="mr-6"
                                  type="checkbox"
                                  id={i}
                                  name="yes"
                                  value="yes"
                                  checked={item.isYes}
                                  onChange={() => handleSelectSheet(item.spreadsheetId, "yes")} />

                                <label className="pr_3" htmlFor={i + 1}>No</label>
                                <input
                                  type="checkbox"
                                  id={i + 1}
                                  name="no"
                                  value="yes"
                                  checked={item.isNo}
                                  onChange={() => handleSelectSheet(item.spreadsheetId, "no")} />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <h5 className="sheet_data_found">No sheet available</h5>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p className="font_bold">Select target custom list for data import</p>
                </div>
                <div className="col-md-6">
                  <select
                    className="drop-select"
                    value={selectedLeadList}
                    onChange={(e) => handleSelectLeadList(e.target.value)}
                  >
                    <option value={""}>Default lead</option>
                    {leadData.map((item, i) => {
                      return (
                        <option key={i} value={item._id}>{item.name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-bs-dismiss="modal"
                onClick={SubmitGoogleSheetData}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
      {/* </BottomSheet> */}
    </>
  );
}

export default GoogleSheets;
