/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import queryString from "query-string";
// MATERIAL COMP IMPORT
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Header from "../Components/common/header";
import { getContent } from "../constants/ApiCall";

const BUCKET_URL = "https://all-user-version-4.s3.us-east-1.amazonaws.com/";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function SharedFile(props) {
  // CONSTANTS
  const { uniqueLinkId } = props.match.params;

  // STATE
  const [loading, setLoader] = useState(true);
  const [showLoaderSpinner, setShowLoaderSpinner] = useState(true);
  const [loaderMessage] = useState("Loading file");
  const [files, setFile] = useState([]);
  const [pageContent, setpageContent] = useState({});
  // METHODS
  // HOOKS
  useEffect(() => {
    console.log("loading contenrt");
    loadContent();
  }, []);
  const loadContent = async () => {
    try {
      let queryParams = queryString.parse(props.location.search);
      const response = await getContent(uniqueLinkId, queryParams.u || "");
      if (response && response.data && response.data.content) {
        const myFiles = [];
        response?.data?.content?.files?.map((file) => {
          myFiles.push({ uri: BUCKET_URL + file.filePath });
        });
        setFile(myFiles);
        setpageContent(response.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
      setShowLoaderSpinner(false);
    }
  };
  const classes = useStyles();
  console.log(pageContent);

  return (
    <LoadingOverlay
      active={loading}
      spinner={showLoaderSpinner}
      text={loaderMessage}
    >
      <div
        className={classes.root}
        style={{
          background: "#FFF",
        }}
      >
        <CssBaseline />

        <Container component="main" className={classes.main} maxWidth="sm">
          <div className="row">
            <div className="template">
              {pageContent?.lead ? (
                <Header
                  pageName={
                    pageContent?.content.details.title &&
                    pageContent?.content.details.title
                  }
                  lead={pageContent?.lead ? pageContent?.lead : ""}
                />
              ) : null}
              <div
                style={{
                  background: "#EBEFF4",
                  marginBottom: "10px",
                }}
              >
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={files}
                />
              </div>

              <div className="footer-area" style={{ background: "#EBEFF4" }}>
                <p>Shared by</p>
                <img
                  src={
                    pageContent?.user?.profile
                      ? `${BUCKET_URL}${pageContent?.user?.profile.filePath}`
                      : window.location.origin + "/assets/images/6.png"
                  }
                  className="circle-image"
                />
                <div className="content-details">
                  <h5>
                    {pageContent?.user?.firstName} {pageContent?.user?.lastName}
                  </h5>

                  <span className="phone-no">{pageContent?.user?.phone}</span>
                  <span>{pageContent?.user?.company_name}</span>
                  <div className="icon-area">
                    <ul>
                      <li>
                        <a href={`tel:${pageContent?.user?.phone}`}>
                          <img
                            className="img-fluid cover-bg-darkened icon-small"
                            src={"../assets/images/Phone.png"}
                            alt={"imgs"}
                          />
                          <br />
                          <span id="sp">Call</span>
                        </a>
                      </li>
                      <li>
                        <a href={`sms://${pageContent?.user?.phone}`}>
                          <img
                            className="img-fluid cover-bg-darkened icon-small"
                            src={"../assets/images/sms.png"}
                            alt={"imgs"}
                          />
                          <br />
                          <span id="sp">SMS</span>
                        </a>
                      </li>
                      <li>
                        <a href={`https://wa.me/${pageContent?.user?.phone}`}>
                          <img
                            className="img-fluid cover-bg-darkened icon-small"
                            src={"../assets/images/wtsapp.png"}
                            alt={"imgs"}
                          />
                          <br />
                          <span id="sp">Whatsapp</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://wa.me/${
                            pageContent?.user?.phone.split("+")[1]
                          }`}
                        >
                          <img
                            className="img-fluid cover-bg-darkened icon-small save-img"
                            src={"../assets/images/save.png"}
                            alt={"imgs"}
                          />
                          <br />
                          <span id="sp">Save</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/*-icon-area-*/}
                </div>
                {/*--content-details--*/}

                {/*-icon-area-*/}
              </div>
              {/*--content-details--*/}
            </div>
          </div>
        </Container>
      </div>
    </LoadingOverlay>
  );
}

export default SharedFile;
