import React, { useState} from "react";
import { generateOtp } from "./authServices";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const Login = () => {
    const [validationError, setValidationError] = useState(false);
    const [logging_in, setLoggingIn] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [value, setValue] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const history = useHistory();

    const handleChange = (
        val,
        _country,
        e,
        _formattedValue
    ) => {
        setValue(val);
        setMobileNumber(val.slice(_country.dialCode.length));
        setCountryCode(_country?.dialCode);
        setValidationError(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!mobileNumber || !mobileNumber.match(/^\d{10}$/)) {
            setValidationError(true);
        } else if (!countryCode) {
            setValidationError(true);
        } else {
            setValidationError(false);
            setLoggingIn(true);
            const data = {
                phone: mobileNumber,
                countryCode: countryCode,
                otpType: "sms",
            };
            try {
                const response = await generateOtp(data);
                if (response && response.status) {
                    localStorage.setItem("phone", mobileNumber);
                    localStorage.setItem("countryCode", countryCode);
                    NotificationManager.success('OTP Sent Successfully!');
                    setLoggingIn(false);
                    history.push("/otp-verify")
                }
            } catch (err) {
                setLoggingIn(false);
                NotificationManager.error("Error occured while sending OTP!");
            }
        }
    };


    return (
        <div className="container">
            <div className="row justify-content-center login_section">
                <div className="col-12 col-md-6 col-lg-5 col-xl-5 login_card">
                    <div className="card login-card">
                        <div className="d-flex flex-column align-items-center">
                            <img
                                src="/assets/images/3sigma-logo.png"
                                alt="logo"
                                className="mt-2 sigm_logo"
                            />
                            <div className="login-header text-center">
                                <h4>Login via phone number</h4>
                                <p>We will send you OTP on this Phone number.</p>
                            </div>
                            <form onSubmit={(e) => handleSubmit(e)} className="login_form">
                                <div className="form_container_login">
                                    <PhoneInput
                                        value={value}
                                        inputClass="phone_input_left"
                                        country="in"
                                        masks={{ fr: '(...) ..-..-..', at: '(....) ...-....' }}
                                        onChange={(val, country, e, formattedValue) =>
                                            handleChange(
                                                val,
                                                country,
                                                e,
                                                formattedValue
                                            )
                                        }

                                        preferredCountries={["in"]}
                                        buttonStyle={{
                                            backgroundColor: "#FFF",
                                            borderRightWidth: 0,
                                            borderTopLeftRadius: 10,
                                            borderBottomLeftRadius: 10,
                                        }}
                                    />
                                    {validationError && (
                                        <small className="text-danger mb-3 ps-0">
                                            Mobile Number is not valid
                                        </small>
                                    )}

                                    <button
                                        type="submit"
                                        className="btn btn-dark w-100"
                                        style={{
                                            backgroundColor: "#3A4B86",
                                            fontFamily: "Gilroy-bold",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "30px"
                                        }}
                                    >
                                        {logging_in ? "Sending Otp..." : "Send OTP"}
                                    </button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};

export default Login;
