import React, { useEffect } from "react";
import { deleteUser } from "./login/authServices";
import { useHistory } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const DeleteAccount = () => {
    const history = useHistory();
    useEffect(() => {
        if (!localStorage.getItem('auth_token') || '') {
            history.push("user-login")
        }
    }, []);
    const userDelete = async () => {
        try {
            const response = await deleteUser();
            if (response && response.status) {
                NotificationManager.success('User deleted Successfully!');
                localStorage.clear();
            }
        } catch (err) {
            NotificationManager.error("Error occured while deleting user!");
        }
    }
    return (
        <div className="delete_user">
            <h1>Delete User Account</h1>
            <p>Users can request 3Sigma CRM to delete their user accounts and associated data from 3Sigma CRM permanently. All data including, but not limited to:</p>
            <ul>
                <li>User account</li>
                <li>Leads</li>
                <li>Activities</li>
                <li>Tasks</li>
                <li>Notes</li>
                <li>Files</li>
                <li>Content</li>
                <li>Reports</li>
                <li>Quotations</li>
                <li>Products</li>
                <li>Identifiers</li>
            </ul>
            <p>will be deleted within 90 days of us receiving the request. Please note that this action is irreversible and cannot be undone. Consider this as the final step; no further action is needed.</p>

            <button
                type="submit"
                className="btn btn-dark btn_delete"
                style={{ width: '15%' }}
                onClick={() => userDelete()}
            >
                Submit
            </button>
            {/* <form action="mailto:vivek@3sigmacrm.com" method="post" enctype="text/plain">
                <button type="submit" value="Delete account" style="padding: 10px 20px; background-color: #f44336; color: white; border: none; cursor: pointer;"> Submit </button>
            </form> */}

            <p>In case of any issues, please reach out to our support team at <a href="mailto:vivek@3sigmacrm.com">vivek@3sigmacrm.com</a>.</p>
            <NotificationContainer />
        </div>
    )
}

export default DeleteAccount