import React from "react";
import LoadingOverlay from "react-loading-overlay";
import ContantFileHeader from "../Components/ContantFileHeader";
import FindOut from "../Components/FindOut";
import ImageList from "../Components/ImageList";
import Maps from "../Components/Maps";
import ShareCard from "../Components/ShareCard";
import TextBlock from "../Components/TextBlock";
import VideoCarousel from "../Components/VideoCarousel";

const SharedPageList = () => {
  return (
    <LoadingOverlay>
      <div style={{ background: "white" }}>
        <ContantFileHeader userName="Vivek Rana" fileName="File name" />
        <ImageList />
        <ImageList />
        <FindOut />
        <TextBlock />
        <VideoCarousel />
        <Maps />
        <ShareCard />
      </div>
    </LoadingOverlay>
  );
};

export default SharedPageList;
