import React from "react";
import PropTypes from 'prop-types';
import { Phone, Mail, Globe, Calendar } from 'lucide-react';

import './OrderView.css';

const OrderView = ({ orderData, currencySymbol, isSmallScreen, utils }) => {
  const {
    getCompleteImageUrl,
    createDate,
    formatWithCommas,
    decimalToEnglish,
  } = utils;

  return (
    <div className="order-container">
      <div className="order-card">
        {/* Modified Header Section */}
        <div className="order-header">
          {/* Left Side - Company Info */}
          <div className="company-info">
            <div className="company-logo">
              <img
                src={getCompleteImageUrl(orderData.organization.iconUrl)}
                alt="Company Logo"
              />
            </div>
            <div className="company-details">
              <h2 className="company-name">{orderData?.organization?.name}</h2>
              {orderData?.organization?.address && (
                <p className="company-address">
                  {orderData.organization.address}
                </p>
              )}
              {orderData?.organization?.phone && (
                <p className="company-contact">
                  <Phone size={14} className="icon" />
                  {orderData.organization.phone}
                </p>
              )}
              {orderData?.organization?.website && (
                <a
                  href={`http://${orderData.organization.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="company-website"
                >
                  <Globe size={14} className="icon" />
                  {orderData.organization.website}
                </a>
              )}
            </div>
          </div>

          {/* Right Side - Order Details Box */}
          <div className="order-details-wrapper">
            <div className="order-details">
              <h1 className="order-title">ORDER</h1>
              <div className="order-info">
                <div className="info-row">
                  <span className="label">Order No:</span>
                  <span className="value">{orderData?.orderId}</span>
                </div>
                <div className="info-row">
                  <span className="label">Date:</span>
                  <span className="value">{createDate(orderData?.createdAt)}</span>
                </div>
                <div className="info-row">
                  <span className="label">Prepared By:</span>
                  <span className="value">{orderData?.createdBy}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-divider"></div>

        {/* Client Section */}
        <div className="client-section">
          <h3 className="section-title">Order For:</h3>
          <div className="client-details">
            {Object.entries(orderData?.lead || {}).map(([key, value]) => {
              if (!value || value === '') return null;
              if (key === '_id' || key === 'createdAt' || key === 'updatedAt') return null;

              return (
                <div key={key} className="client-info">
                  {key === 'phone' ? (
                    <>
                      <Phone size={16} className="icon" />
                      <span className="value">{value}</span>
                    </>
                  ) : key === 'email' ? (
                    <>
                      <Mail size={16} className="icon" />
                      <span className="value">{value}</span>
                    </>
                  ) : (
                    <>
                      <span className="label">
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}:
                      </span>
                      <span className="value">{value}</span>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="section-divider"></div>

        {/* Products Table */}
        <div className="table-container">
          <table className="products-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Description</th>
                <th className="text-right">Unit Price</th>
                <th className="text-right">Qty</th>
                <th className="text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              {orderData?.products?.map((product, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className="product-name">{product.name}</td>
                  <td>{product.description}</td>
                  <td className="text-right">
                    {currencySymbol} {formatWithCommas(product.unitPrice)}
                  </td>
                  <td className="text-right">{product.quantity}</td>
                  <td className="text-right">
                    {currencySymbol} {formatWithCommas(product.unitPrice * product.quantity)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="section-divider"></div>

        {/* Summary & Amount in Words Section */}
        <div className="summary-wrapper">
          {/* Amount in Words */}
          <div className="amount-words">
            <h4 className="section-title">Amount in Words</h4>
            <p className="words">
              {currencySymbol}: {decimalToEnglish(orderData.total)}
            </p>
            
            {/* Notes Section */}
            {orderData?.notes && (
              <div className="notes-section">
                <h4 className="section-title">Notes</h4>
                <p className="notes-text">{orderData.notes}</p>
              </div>
            )}
          </div>

          {/* Calculations */}
          <div className="summary-section">
            <div className="calculations">
            {orderData.receivedAmount && orderData.receivedAmount !== 0 && (
                <>
                  <div className="calc-row">
                    <span className="label">Payment Mode</span>
                    <span className="value capitalize">
                      {orderData.receivedPaymentMode}
                    </span>
                  </div>
                  <div className="calc-row">
                    <span className="label">Received Amount</span>
                    <span className="value">
                      {currencySymbol} {formatWithCommas(orderData.receivedAmount)}
                    </span>
                  </div>
                </>
              )}

              <div className="calc-row">
                <span className="label">Subtotal</span>
                <span className="value">
                  {currencySymbol} {formatWithCommas(orderData.subTotal)}
                </span>
              </div>

              {orderData?.discount && (
                <div className="calc-row">
                  <span className="label">
                    Discount ({orderData.discount.type === "fixed" ? "fixed" : `${orderData.discount.rate}%`})
                  </span>
                  <span className="value">
                    {currencySymbol} {formatWithCommas(orderData.discount.amount)}
                  </span>
                </div>
              )}

              {orderData?.additionalCharges?.map((charge, index) => (
                <div key={index} className="calc-row">
                  <span className="label">
                    {charge.title} ({charge.type === "fixed" ? "fixed" : `${charge.rate}%`})
                  </span>
                  <span className="value">
                    {currencySymbol} {formatWithCommas(charge.amount)}
                  </span>
                </div>
              ))}

              {orderData?.taxes?.map((tax, index) => (
                <div key={index} className="calc-row">
                  <span className="label">
                    {tax.title} ({tax.rate}%)
                  </span>
                  <span className="value">
                    {currencySymbol} {formatWithCommas(tax.amount)}
                  </span>
                </div>
              ))}

              <div className="total-row">
                <span className="label">TOTAL</span>
                <span className="value">
                  {currencySymbol} {formatWithCommas(orderData.total)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="section-divider"></div>

        {/* Bank Details */}
        <div className="order-footer">
          <div className="footer-card bank-details">
            <h4 className="section-title">Bank Details</h4>
            <div className="bank-info">
              <div className="bank-row">
                <span className="bank-label">Bank Name</span>
                <span className="bank-value">{orderData?.bankDetails?.bankName}</span>
              </div>
              <div className="bank-row">
                <span className="bank-label">Account Number</span>
                <span className="bank-value">{orderData?.bankDetails?.accountNumber}</span>
              </div>
              <div className="bank-row">
                <span className="bank-label">IFSC Code</span>
                <span className="bank-value">{orderData?.bankDetails?.ifscCode}</span>
              </div>
              <div className="bank-row">
                <span className="bank-label">Account Holder</span>
                <span className="bank-value">{orderData?.bankDetails?.accountHolderName}</span>
              </div>
            </div>
          </div>

          {/* Terms and Conditions Section */}
          {orderData?.termsAndCondition && (
            <div className="footer-card terms-conditions">
              <h4 className="section-title">Terms and Conditions</h4>
              <div className="terms-content">
                {orderData?.termsAndCondition}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

OrderView.propTypes = {
  orderData: PropTypes.object.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  isSmallScreen: PropTypes.bool,
  utils: PropTypes.shape({
    getCompleteImageUrl: PropTypes.func.isRequired,
    createDate: PropTypes.func.isRequired,
    formatWithCommas: PropTypes.func.isRequired,
    decimalToEnglish: PropTypes.func.isRequired,
  }).isRequired,
};

export default OrderView;