import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function Button({ children, onClick }) {
  const useStyles = makeStyles(() => ({
    button: {
      width: "90%",
      margin: "50px auto 0 auto",
      padding: "13px 0",
      height: "53px",
      background: "rgba(58, 75, 134, 0.9)",
      borderRadius: "15px",
      border: "none !important",
      textAlign: "center",
      fontFamily: "Gilroy",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "24px",
      color: "#FFFFFF",
      "&:focus": {
        border: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    },
  }));

  const classes = useStyles();

  return (
    <button component="button" className={classes.button} onClick={onClick}>
      {children}
    </button>
  );
}
