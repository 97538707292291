import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import queryString from "query-string";
import "./quotation.module.css";
import ProgressBar from "react-progressbar-on-scroll";

// API CALL IMPORT
import { getContent } from "../constants/ApiCall";
import Header from "../Components/common/header";

const BUCKET_URL = "https://all-user-version-4.s3.us-east-1.amazonaws.com/";

const FullScreenImageViewer = ({ images, startIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const buttonStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div style={{
        position: 'relative',
        width: '90%',
        height: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <img
          src={BUCKET_URL + images[currentIndex].filePath}
          alt={images[currentIndex].fileName || `Image ${currentIndex + 1}`}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      </div>
      <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
        <button onClick={handlePrev} style={buttonStyle}>Previous</button>
        <button onClick={handleNext} style={buttonStyle}>Next</button>
        <button onClick={onClose} style={buttonStyle}>Close</button>
      </div>
    </div>
  );
};

function SharedFile(props) {
  const { uniqueLinkId } = props.match.params;

  const [isActive, setLoader] = useState(true);
  const [showLoaderSpinner, setShowLoaderSpinner] = useState(true);
  const [loaderMessage, setLoaderMessage] = useState("Loading Page");
  const [pageContent, setPageContent] = useState(null);
  const [pdfArr, setPdfArr] = useState([]);
  const [imgArr, setImgArr] = useState([]);
  const [fullscreenViewerOpen, setFullscreenViewerOpen] = useState(false);
  const [startImageIndex, setStartImageIndex] = useState(0);

  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
    if (pageContent && pageContent.content.details.location) {
      setTimeout(() => {
        window.initMap(
          pageContent.content.details.location.latitude,
          pageContent.content.details.location.longitude
        );
      }, 2000);
    }
  }, [pageContent]);

  const loadContent = async () => {
    try {
      let queryParams = queryString.parse(props.location.search);
      const response = await getContent(uniqueLinkId, queryParams.u || "");
      console.log("page", response);
      if (response.data.content.files) {
        const files = response.data.content.files;
        const pdfs = [];
        const images = [];
        files.forEach((_item) => {
          const fileExt = _item.fileName.split('.').pop().toLowerCase();
          if (["jpg", "png", "gif", "jpeg"].includes(fileExt)) {
            images.push(_item);
          } else if (fileExt === "pdf") {
            pdfs.push(_item.filePath);
          }
        });
        setPdfArr(pdfs);
        setImgArr(images);
      }
      setPageContent(response.data);
    } catch (error) {
      console.error("Error loading content:", error);
      // TODO: Add user-friendly error handling
    } finally {
      setLoader(false);
    }
  };

  const popYouTubeId = (youTubeUrl) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = youTubeUrl.match(regExp);
    const youTubeId = (match && match[2].length === 11) ? match[2] : null;
    return youTubeId ? `https://www.youtube.com/embed/${youTubeId}` : null;
  };

  const openFullscreenViewer = (index) => {
    setStartImageIndex(index);
    setFullscreenViewerOpen(true);
  };

  const closeFullscreenViewer = () => {
    setFullscreenViewerOpen(false);
  };

  return (
    <LoadingOverlay
      active={isActive}
      spinner={showLoaderSpinner}
      text={loaderMessage}
    >
      <div className="container container-div" style={{ minHeight: "100vh", background: "#FFF" }}>
        <Header
          pageName={pageContent?.content.details.title}
          organisation={pageContent?.user.organization.name}
          lead={pageContent?.lead || ""}
        />
        {!isActive && (
          <div className="row">
            <div className="template">
              {imgArr.map((file, index) => (
                <div key={index} className="second-img" style={{ margin: '10px 0', cursor: 'pointer' }} onClick={() => openFullscreenViewer(index)}>
                  <img
                    className="img-fluid cover-bg-darkened"
                    style={{ width: "100%" }}
                    src={BUCKET_URL + file.filePath}
                    alt={file.fileName || `Image ${index + 1}`}
                    loading="lazy"
                  />
                </div>
              ))}
              {fullscreenViewerOpen && (
                <FullScreenImageViewer
                  images={imgArr}
                  startIndex={startImageIndex}
                  onClose={closeFullscreenViewer}
                />
              )}
              {pdfArr.map((file, index) => (
                <div key={index} className="second-img">
                  <DocViewer
                    pluginRenderers={DocViewerRenderers}
                    documents={[{ uri: BUCKET_URL + file }]}
                  />
                </div>
              ))}
              <div className="image-content">
              <p style={{ fontSize: '18px', color: '#333', lineHeight: '1.6' }}>{pageContent?.content.details.description}</p>
              </div>
              <div className="button-area">
                <p>Want to find out more?</p>
                <p>
                  <a href={`https://wa.me/${pageContent?.user?.phone}`}>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="contact-text align-items-center">
                      <span style={{ marginBottom: '8px' }}>Contact Me</span>
                        <img
    src={"../assets/images/whatsapp.svg"}
    alt={"WhatsApp"}
    style={{ width: '24px', height: '24px' }}
  />
                      </div>
                    </div>
                  </a>
                </p>
              </div>
              {pageContent?.content.details.videoUrl &&
                pageContent.content.details.videoUrl.split(",").map((item, index) => {
                  const embedUrl = popYouTubeId(item);
                  return embedUrl ? (
                    <div key={index} className="video-img" style={{ position: "relative" }}>
                      <iframe
                        src={embedUrl}
                        style={{
                          borderRadius: "5px",
                          height: "100%",
                          width: "100%",
                          aspectRatio: "16 / 9",
                        }}
                        title={`YouTube video ${index + 1}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  ) : null;
                })}
              {pageContent?.content.details.location && (
                <div className="map-area">
                  <div id="map"></div>
                </div>
              )}
              <div className="footer-area" style={{ background: "#EBEFF4" }}>
                <p style={{ fontSize: "20px" }}>Shared by</p>
                <img
                  src={pageContent?.user?.profile
                    ? `${BUCKET_URL}${pageContent.user.profile.filePath}`
                    : window.location.origin + "/assets/images/6.png"}
                  className="circle-image"
                  alt="Profile"
                />
                <div className="content-details">
                  <h5>{`${pageContent?.user?.firstName} ${pageContent?.user?.lastName}`}</h5>
                  <p>{pageContent?.user.organization.name}</p>
                  <span className="phone-no">{pageContent?.user?.phone}</span>
                  <div className="icon-area">
                    <ul>
                      <li>
                        <a href={`tel:${pageContent?.user?.phone}`}>
                          <img
                            className="img-fluid cover-bg-darkened icon-small"
                            src={"/assets/images/Phone.png"}
                            alt={"Phone"}
                          />
                          <br />
                          <span id="sp" style={{ color: "#0E9CFF" }}>Call</span>
                        </a>
                      </li>
                      <li>
                        <a href={`sms:${pageContent?.user?.phone}`}>
                          <img
                            className="img-fluid cover-bg-darkened icon-small"
                            src={"/assets/images/sms.png"}
                            alt={"SMS"}
                          />
                          <br />
                          <span id="sp" style={{ color: "#0b2357" }}>SMS</span>
                        </a>
                      </li>
                      <li>
                        <a href={`https://wa.me/${pageContent?.user?.phone.split("+")[1]}`}>
                          <img
                            className="img-fluid cover-bg-darkened icon-small"
                            src={"/assets/images/wtsapp.png"}
                            alt={"WhatsApp"}
                          />
                          <br />
                          <span id="sp" style={{ color: "#0ca30b" }}>WhatsApp</span>
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={(e) => { e.preventDefault(); /* Implement save contact functionality */ }}>
                          <img
                            className="img-fluid cover-bg-darkened icon-small save-img"
                            src={"../assets/images/save.png"}
                            alt={"Save"}
                          />
                          <br />
                          <span id="sp" style={{ color: "#68042e" }}>Save</span>
                        </a>
                      </li>
                    </ul>
                    <p style={{ color: "#389cdf", fontWeight: "bold", fontSize: 20 }}>
                      Powered By 3Sigma
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </LoadingOverlay>
  );
}

export default SharedFile;