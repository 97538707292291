import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
// MATERIAL COMP IMPORT
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Integration from "../Components/Integration Components/Integration";
import { getGoogleTokens } from "../constants/ApiCall";

// UTILS CALL
import { API_URL } from "../constants/utils";
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleClientService } from '../constants/Google_client_service';

const axios = require('axios')



const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },
    main: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));
const inputStyle = {
    borderRadius: "10px",
    height: "40px",
    border: "none",
    outline: "none",
    width: "90%",
};

const bottomBtn = {
    backgroundColor: "#3A4B86",
    padding: "10px",
    color: "#FFFFFF",
    borderRadius: "15px",
    textAlign: "center",
    width: "92%",
    fontWeight: "500",
    fontSize: "20px",
    justifyContent: "center",
    alignItems: "center",
    bottom: "20px",
    marginTop: "40px",
};
const copyIcon = {
    backgroundColor: "white",
    alignItems: "center",
    borderRadius: "10px",
    paddingRight: "10px",
};

const iframeStyle = {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "20px",
    borderRadius: "10px",
};
function GoogleMeet(props) {
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("Loading");
    const [tokenData, setTokenData] = useState({});

    const googleAuth = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (codeResponse) => {
            const tokens = await axios.post(
                `${GoogleClientService.web.token_uri}`, {
                code: codeResponse.code,
                grant_type: 'authorization_code',
                client_id: `${GoogleClientService.web.client_id}`,
                client_secret: `${GoogleClientService.web.client_secret}`,
                redirect_uri: `${GoogleClientService.web.redirect_uris[0]}`
            });
            setTokenData(tokens.data);
        },
        scope: 'https://www.googleapis.com/auth/calendar.events',
        onError: errorResponse => console.log(errorResponse),
    });

    useEffect(() => {
        getGoogleTokens(
            {
                "tokens": {
                    "access_token": `${tokenData.access_token}`,
                    "scope": `${tokenData.scope}`,
                    "token_type": `${tokenData.token_type}`,
                    "id_token": `${tokenData.id_token}`,
                    "expiry_date": tokenData.expires_in
                }
            }
        )
    }, [tokenData])

    const classes = useStyles();

    return (
        <BottomSheet
            open={true}
            snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 2]}
            defaultSnap={({ lastSnap, snapPoints }) =>
                lastSnap ?? Math.max(...snapPoints)
            }
        >
            <LoadingOverlay active={loading} spinner text={loadingText}>
                <div className="container">
                    <Integration
                        title={"Google Meet"}
                        logo={window.location.origin + "/assets/images/meet.png"}
                        width='25px'
                        isRounded
                    />
                    <CssBaseline />
                    <Container component="main" className={classes.main} maxWidth="sm">
                        <>
                            <Typography variant="body1" component="h2" gutterBottom>
                                Now create Google meet events right rom 3sigma CRM. You will also be able to share meeting details with your leads. We will also update your google calendar with meeting details .
                            </Typography>
                            <Typography variant="body1" component="h5" className="mt-5 pt-5 font-weight-bold">
                                How does Google Meet integration work?
                            </Typography>
                            <div style={iframeStyle}>
                                <iframe
                                    width="300"
                                    height="150"
                                    frameBorder="0"
                                    // borderRadius="5"
                                    title="youtube"
                                    style={{ borderRadius: "10px" }}
                                    src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0"
                                ></iframe>
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={googleAuth}
                                style={bottomBtn}
                                className="btn"
                            >
                                Connect
                            </Button>
                        </>
                    </Container>
                </div>
            </LoadingOverlay>
        </BottomSheet>
    );
}

export default GoogleMeet;
