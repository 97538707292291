import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import Integration from "../Components/Integration Components/Integration";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  createUserIntegration,
  disconnectIntegration,
  getUserIntegration,
} from "../constants/ApiCall";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import Button from "../Components/Integration Components/Button";
import Header from "../Components/Integration Components/Header";
import Logo from "../images/razorpay.png";

function Razorpay(props) {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [errorText, setErrorText] = useState("");
  const [integration, setIntegration] = useState(false);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [integrationDate, setIntegrationDate] = useState(new Date());

  useEffect(() => {
    getIntegration();
  }, []);

  const getIntegration = async () => {
    const response = await getUserIntegration();
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "razorpay"
      );
      if (_integration) {
        setIntegration(_integration);
        setIntegrationDetails(_integration.details[0]);
        setData({
          key: _integration.details[0]?.key || "",
          secret: _integration.details[0]?.secret || "",
        });
        setIntegrationDate(_integration.createdAt);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setErrorText(""); // Clear error when user starts typing
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingText("Connecting...");
    setErrorText(""); // Clear any previous errors

    const payload = {
      details: {
        key: data?.key,
        secret: data?.secret,
      }
    };

    try {
      const response = await createUserIntegration("razorpay", payload);
      if (response && response.status) {
        props.history.push({
          pathname: `/integration/success`,
        });
      } else {
        throw new Error(response.message || "Error in integration");
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setErrorText("Invalid input: Please check your API key and secret.");
      } else {
        setErrorText(error.message || "An unexpected error occurred. Please try again.");
      }
      NotificationManager.error(errorText);
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnectIntegration = async () => {
    setLoading(true);
    setLoadingText("Disconnecting...");
    try {
      const response = await disconnectIntegration(
        integration?.userIntegrationId
      );
      if (response && response.status) {
        props.history.push({
          pathname: `/disconnect/success`,
        });
      } else {
        throw new Error("Error in disconnecting");
      }
    } catch (error) {
      setErrorText("Failed to disconnect. Please try again.");
      NotificationManager.error(errorText);
    } finally {
      setLoading(false);
    }
  };

  const useStyles = makeStyles(() => ({
    container: {
      padding: integration ? "0 24px" : "0 25px",
      "& button": {
        width: "330px",
        position: "relative",
        transform: "translateX(-50%)",
        left: "50%",
        marginBottom: "30px !important",
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
      margin: integration ? "0 10px" : "0",
    },
    input: {
      width: "100%",
      minWidth: "331px",
      height: "39px",
      background: "#FFFFFF",
      borderRadius: "10px",
      border: "1px solid #ccc",
      padding: "10px 17px",
      "&::placeholder": {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        color: "rgba(0, 0, 0, 0.5)",
      },
      "&:focus": {
        outline: "none",
        borderColor: "#007bff",
      },
    },
    errorText: {
      color: "red",
      fontSize: "14px",
      marginTop: "5px",
    },
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"Razorpay integration"}
            logo={<img src={Logo} alt="Razorpay" width="60" height="60" />}
          />
          <div className={classes.text}>
            Integrate your Razorpay account to automatically generate payment links
            for collecting payments from forms or invoices.
          </div>
          <div className="mt-4 form_lbl">Razorpay API Key</div>
          <input
            type="text"
            className={"mt-1 " + classes.input}
            name="key"
            onChange={handleChange}
            value={data?.key}
            placeholder="Enter API Key"
          />
          <div className="mt-4 form_lbl">Razorpay API Secret</div>
          <input
            type="password"
            className={"mt-1 " + classes.input}
            name="secret"
            onChange={handleChange}
            value={data?.secret}
            placeholder="Enter API Secret"
          />
          {errorText && <p className={classes.errorText}>{errorText}</p>}
          {integration && (
            <div className={"mt-4 " + classes.text}>
              Razorpay Connected successfully on{" "}
              {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
            </div>
          )}

          {integration ? (
            <Button onClick={handleDisconnectIntegration}>Disconnect</Button>
          ) : (
            <Button onClick={onSubmit}>Connect</Button>
          )}
        </div>
      </LoadingOverlay>
      <NotificationContainer />
    </BottomSheet>
  );
}

export default Razorpay;
