import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  createUserIntegration,
  disconnectIntegration,
  getUserIntegration,
  getLeadList,
  updateUserIntegration,
} from "../constants/ApiCall";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";

import Integration from "../Components/Integration Components/Integration";
import Button from "../Components/Integration Components/Button";
import Header from "../Components/Integration Components/Header";
import Logo from "../images/magic_bricks.jpeg";
import { makeStyles } from "@material-ui/core";

function MagicBricks(props) {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [integration, setIntegration] = useState(null);
  const [leadData, setLeadData] = useState([]);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [url, setURL] = useState("");
  const [copyURL, setCopyURL] = useState("");
  const [copied, setCopied] = useState(false);
  const [selectedLeadList, setSelectedLeadList] = useState("");
  const [integrationDate, setIntegrationDate] = useState(new Date());

  useEffect(() => {
    connectMagicBricks();
  }, []);

  const connectMagicBricks = async () => {
    setLoading(true);
    setLoadingText("Connecting...");
    let details = {
      list: selectedLeadList,
    };
    const response = await createUserIntegration("magic_bricks", {});
    setLoading(false);
    if (response && response.status) {
      getIntegration();
    }
  };

  const getIntegration = async () => {
    const response = await getUserIntegration();
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "magic_bricks"
      );
      getLeadListData();

      if (_integration) {
        setIntegration(_integration);
        setIntegrationDetails(_integration.details);
        setData({ APIkey: _integration.details.apiKey });
        setIntegrationDate(_integration.createdAt);
        setSelectedLeadList(_integration.details.list);
        let content = `Please share my Magicbricks leads with 3Sigma CRM.\n
My details
CRM service provider name :3sigma 
Working Endpoint :${process.env.REACT_APP_DEV_API_WEBHOOK_URL}${_integration.integrationKey}/${_integration.details.apiKey}
Sample URL :${process.env.REACT_APP_DEV_API_WEBHOOK_URL}${_integration.integrationKey}/${_integration.details.apiKey}
API Key :no auth needed
URL Parameters :https://www.postman.com/threesigmacrm/workspace/3sigma-api-docs/request/20352867-eea2339a-ca61-4d11-9295-f1d510e181cb
`;
        setData({
          APIkey: content,
        });
        setCopyURL(content);
        setURL(content);
      }
    }
  };

  const getLeadListData = async () => {
    let params = {
      isAscending: false,
      page: 1,
      perPage: 15,
    };
    const response = await getLeadList(params);
    if (response && response.status) {
      setLeadData(response.data);
    } else {
      setLeadData([]);
    }
  };

  const handleDisconnectIntegration = async () => {
    setLoading(true);
    setLoadingText("Disconnecting...");
    const response = await disconnectIntegration(
      integration?.userIntegrationId
    );
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/disconnect/success`,
      });
    } else {
      NotificationManager.error("Error in integration");
    }
  };
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setData({ ...data, [name]: value });
  // };
  const handleSelectLeadList = async (lead_id) => {
    setSelectedLeadList(lead_id);
    if (integration) {
      let tempObj = {};
      if (lead_id) {
        tempObj = {
          ...integrationDetails,
          list: lead_id,
        };
      } else {
        tempObj = {
          ...integrationDetails,
        };
      }

      const response = await updateUserIntegration(
        integration?.userIntegrationId,
        tempObj
      );
      if (response && response.status) {
        NotificationManager.success(response.message);
      } else {
        NotificationManager.error("Error in integration");
      }
    }
  };
  const useStyles = makeStyles(() => ({
    container: {
      padding: "0 15px",
      "& .form_lbl": {
        fontFamily: "'Gilroy-SemiBold' !important",
      },
      "& button": {
        width: "330px",
        position: "relative",
        marginBottom: "50px !important",
        marginTop: "70px !important",
        display: "flex",
        alignItems: "center",
        paddingLeft: "33px",
        gap: "9px",
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
      padding: "0 7px",
    },
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"Magicbricks integration"}
            logo={<img src={Logo} alt="Magicbricks" width="36" height="35" />}
          />
          <div className={classes.text}>
            Connect your Magicbricks account and receive inquiries
            automatically.
          </div>
          <div className={classes.text + " mt-4"}>
            Share the below message to your Magicbricks relationship manager .
            Once activated you will start receiving Magicbricks leads
            automatically in 3sigma CRM
          </div>
          {/*integration && (
            <div className={"pt-5 " + classes.text}>              Magicbricks Connected successfully on{" "}
              {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
            </div>
          )*/}
          <div className="copyIcon mt-5">
            <textarea
              rows="8"
              cols="80"
              className={"mt-2 inputStyle font-bold"}
              style={{ padding: "10px 17px", fontWeight: "700" }}
              name="APIkey"
              value={url}
            />
            <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
              {copied ? (
                <img
                  src={window.location.origin + "/assets/images/checked.ico"}
                  alt="copy-icon"
                  className="success_tick"
                />
              ) : (
                <img
                  src={window.location.origin + "/assets/images/copy.png"}
                  alt="copy-icon"
                  style={{ float: "right", marginTop: "15px" }}
                />
              )}
            </CopyToClipboard>
          </div>
          <a
            href={`https://api.whatsapp.com/send?phone=&text=${url}`}
            target="_blank"
          >
            <Button
            // onClick={connectMagicBricks}
            >
              <svg
                width="30"
                height="26"
                viewBox="0 0 30 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0603 0C6.82914 0 0.120603 5.785 0.120603 12.883C0.120603 15.158 0.81407 17.368 2.11055 19.318L0 26L7.91457 24.206C10.1005 25.233 12.5578 25.779 15.0603 25.779C23.2915 25.779 30 19.994 30 12.896C30 9.451 28.4472 6.214 25.6281 3.783C22.809 1.339 19.0553 0 15.0603 0ZM15.0754 2.171C18.392 2.171 21.4975 3.289 23.8492 5.317C26.1859 7.345 27.4824 10.036 27.4824 12.896C27.4824 18.798 21.9045 23.595 15.0603 23.595C12.8291 23.595 10.6432 23.088 8.74372 22.1L8.29146 21.879L3.58794 22.945L4.8392 18.993L4.53769 18.577C3.30151 16.9 2.63819 14.911 2.63819 12.883C2.65327 6.981 8.21608 2.171 15.0754 2.171ZM9.76884 6.929C9.52764 6.929 9.1206 7.007 8.77387 7.332C8.44221 7.657 7.46231 8.45 7.46231 10.023C7.46231 11.609 8.80402 13.13 8.96985 13.351C9.1809 13.572 11.6231 16.822 15.3769 18.2C16.2663 18.551 16.9598 18.746 17.5025 18.889C18.392 19.136 19.206 19.097 19.8543 19.019C20.5779 18.928 22.0553 18.239 22.3719 17.485C22.6884 16.731 22.6884 16.094 22.598 15.951C22.4925 15.821 22.2513 15.743 21.8744 15.6C21.4975 15.418 19.6583 14.638 19.3266 14.534C18.9799 14.43 18.7688 14.378 18.4824 14.69C18.2412 15.015 17.5176 15.743 17.3065 15.951C17.0804 16.172 16.8693 16.198 16.5075 16.042C16.1156 15.873 14.9095 15.535 13.4925 14.443C12.3769 13.585 11.6382 12.532 11.4121 12.207C11.2312 11.895 11.397 11.7 11.5779 11.557C11.7437 11.414 11.9849 11.18 12.1357 10.985C12.3317 10.803 12.392 10.66 12.5126 10.452C12.6332 10.231 12.5729 10.049 12.4824 9.893C12.392 9.75 11.6382 8.138 11.3216 7.501C11.0201 6.877 10.7186 6.955 10.4774 6.942C10.2663 6.942 10.0251 6.929 9.76884 6.929Z"
                  fill="#25D366"
                />
              </svg>
              Share on Whatsapp
            </Button>
          </a>
        </div>
      </LoadingOverlay>
      <NotificationContainer />
    </BottomSheet>
  );
}

export default MagicBricks;
