import React from "react";
import "../../sharedpage.module.css";
import ProgressBar from "react-progressbar-on-scroll";
const Header = ({ pageName, lead, organisation }) => {
  return (
    <>
      <div className="header">
        <div className="inner-content">
          <div className="logo">
            <img src="/assets/images/3sigma-logo.png" alt="logo" />
            <h4>{organisation}</h4>
          </div>
          <div className="right-content">
            <h4>{pageName}</h4>
            {lead !== "" ? (
              <span className="">
                <span
                  style={{
                    textTransform: "Uppercase",
                  }}
                >
                  Prepared For
                </span>{" "}
                {lead}{" "}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mobile-view">
        <h4>{pageName}</h4>
        {lead !== "" ? (
          <span className="">
            <span
              style={{
                textTransform: "Uppercase",
              }}
            >
              Prepared For
            </span>{" "}
            {lead}{" "}
          </span>
        ) : null}
      </div>
      <ProgressBar
        color="#307FE2"
        // gradient={true}
        height={5}
        gradientColor="#FF5733"
      />
    </>
  );
};

export default Header;
