import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  createUserIntegration,
  disconnectIntegration,
  getUserIntegration,
  getLeadList,
  updateUserIntegration,
} from "../constants/ApiCall";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import moment from "moment";

import Header from "../Components/Integration Components/Header";
import Integration from "../Components/Integration Components/Integration";
import Button from "../Components/Integration Components/Button";

import Logo from "../images/wix.jpg";
import { makeStyles } from "@material-ui/core";

function Wix(props) {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [errorText, setErrorText] = useState();
  const [copied, setCopied] = useState(false);
  const [integration, setIntegration] = useState(true);
  const [leadData, setLeadData] = useState([]);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [integrationDate, setIntegrationDate] = useState(new Date());
  const [selectedLeadList, setSelectedLeadList] = useState("");

  useEffect(() => {
    getIntegration();
  }, []);

  const getIntegration = async () => {
    const response = await getUserIntegration();
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "wix"
      );
      if (_integration) {
        getLeadListData();
        setIntegration(_integration);
        setIntegrationDate(_integration.createdAt);
        setIntegrationDetails(_integration.details);
        setData({ apiKey: _integration.details.apiKey || "" });
      }
    }
  };

  const getLeadListData = async () => {
    let params = {
      isAscending: false,
      page: 1,
      perPage: 15,
    };
    const response = await getLeadList(params);
    if (response && response.status) {
      setLeadData(response.data);
    } else {
      setLeadData([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingText("Connecting...");
    let payload = {
      key: data.key,
      profileId: data.profileId,
      userId: data.userId,
      ...(selectedLeadList != "" && {
        list: selectedLeadList,
      }),
    };
    const response = await createUserIntegration("wix", [payload]);
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/integration/success`,
      });
    } else {
      setErrorText(response ? response.message : "Error in integration");
    }
  };

  const handleDisconnectIntegration = async () => {
    setLoading(true);
    setLoadingText("Disconnecting...");
    const response = await disconnectIntegration(
      integration?.userIntegrationId
    );
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/disconnect/success`,
      });
    } else {
      NotificationManager.error("Error in integration");
    }
  };

  const handleSelectLeadList = async (lead_id) => {
    setSelectedLeadList(lead_id);
    if (integration) {
      let tempObj = {};
      if (lead_id) {
        tempObj = {
          ...integrationDetails,
          list: lead_id,
        };
      } else {
        tempObj = {
          ...integrationDetails,
        };
      }

      const response = await updateUserIntegration(
        integration?.userIntegrationId,
        tempObj
      );
      if (response && response.status) {
        NotificationManager.success(response.message);
      } else {
        NotificationManager.error("Error in integration");
      }
    }
  };
  const useStyles = makeStyles(() => ({
    container: {
      padding: "0 19px",
      "& .form_lbl": {
        fontFamily: "'Gilroy-SemiBold' !important",
      },
      "& button": {
        width: "330px",
        position: "relative",
        marginBottom: "50px !important",
        marginTop: "70px !important",
        display: "flex",
        alignItems: "center",
        paddingLeft: "33px",
        gap: "9px"
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
    },
    iframe: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "20px",
      borderRadius: "10px",
    },
    selectWrap: {
      width: "fit-content",
      height: "40px",
      width: "100%",
      position: "relative",
      "& svg": {
        position: "absolute",
        right: "22px",
        top: "16.5px",
      },
    },
    select: {
      height: "40px",
      padding: "0 15px",
      color: "rgba(0, 0, 0, 0.5)",
      fontWeight: "600",
      appearance: "none",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
    },
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"Wix website"}
            logo={
              <img
                src={Logo}
                alt="Zapier"
                width="40"
                height="40"
                style={{ borderRadius: "50%" }}
              />
            }
          />
          <div className={classes.text}>
            You can connect our Wix website and automatically import inquiries
            submitted through wix forms in 3sigma crm.
          </div>
          {integration ? (
            <div className={classes.text + " mt-5 pb-1 ms-2"}>
              <a
                href="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0"
                target="_blank"
                style={{ color: "inherit", textDecoration: "underline" }}
                className=" d-flex align-items-center gap-2"
              >
                How does Wix integration work?
                <svg
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 10L13.19 7L8 4V10ZM19.56 2.17C19.69 2.64 19.78 3.27 19.84 4.07C19.91 4.87 19.94 5.56 19.94 6.16L20 7C20 9.19 19.84 10.8 19.56 11.83C19.31 12.73 18.73 13.31 17.83 13.56C17.36 13.69 16.5 13.78 15.18 13.84C13.88 13.91 12.69 13.94 11.59 13.94L10 14C5.81 14 3.2 13.84 2.17 13.56C1.27 13.31 0.69 12.73 0.44 11.83C0.31 11.36 0.22 10.73 0.16 9.93C0.0900001 9.13 0.0599999 8.44 0.0599999 7.84L0 7C0 4.81 0.16 3.2 0.44 2.17C0.69 1.27 1.27 0.69 2.17 0.44C2.64 0.31 3.5 0.22 4.82 0.16C6.12 0.0899998 7.31 0.0599999 8.41 0.0599999L10 0C14.19 0 16.8 0.16 17.83 0.44C18.73 0.69 19.31 1.27 19.56 2.17Z"
                    fill="#9F1212"
                  />
                </svg>
              </a>
            </div>
          ) : (
            <>
              <div className={classes.text + " mt-4 ms-3"}>
                How does Wix integration work?
              </div>
              <div className={classes.iframe + " iframe_container"}>
                <iframe
                  width="300"
                  height="150"
                  borderRadius="5"
                  title="youtube"
                  style={{ borderRadius: "10px" }}
                  src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0"
                ></iframe>
              </div>
            </>
          )}
          {integration && (
            <>
              <div className={"mt-3 ms-2 " + classes.text}>
                Wix Connected successfully on{" "}
                {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
              </div>
              <div className="mt-5 form_lbl">Select Custom lead list</div>
              <label className={classes.selectWrap + " mb-5"}>
                <select
                  onChange={(e) => handleSelectLeadList(e.target.value)}
                  className={classes.select + " mb-5"}
                >
                  <option value={""} disabled selected>
                    Select list{" "}
                  </option>
                  {leadData.map((item, i) => {
                    return (
                      <option key={i} value={item._id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.41 0L6 4.59L10.59 0L12 1.42L6 7.42L0 1.42L1.41 0Z"
                    fill="black"
                  />
                </svg>
              </label>
            </>
          )}

          {integration ? (
            <Button onClick={handleDisconnectIntegration}>Disconnect</Button>
          ) : (
            <Button onClick={onSubmit}>Connect</Button>
          )}
        </div>
      </LoadingOverlay>
      <NotificationContainer />
    </BottomSheet>
  );
}

export default Wix;
