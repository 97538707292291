import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import OrderView from "./OrderView";
import OrderPdfService from "../Components/OrderPdfService";
import OrderPdfContainer from "../Components/OrderPdfContainer";
import html2pdf from "html2pdf.js";
import "./quotation.module.css";
import "./custom.module.css";


const BUCKET_URL = process.env.BUCKET_URL || "https://all-user-version-4.s3.us-east-1.amazonaws.com/";

const Order = () => {
  console.log('[order] Component initializing');

  // Refs for preventing double exports and handling unmounts
  const isMounted = useRef(true);
  const exportInProgress = useRef(false);
  const initialRender = useRef(true);

  // State Management
  const [orderData, setorderData] = useState({
    products: [],
    organization: {},
    lead: {},
    bankDetails: {},
    discount: null,
    taxes: [],
    additionalCharges: []
  });
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pdfGenerating, setPdfGenerating] = useState(false);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 576px)" });

  // Cleanup on unmount
  useEffect(() => {
    // Skip on initial render
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    return () => {
      console.log('[order] Component unmounting, cleaning up');
      isMounted.current = false;
      exportInProgress.current = false;
    };
  }, []);

  // Ensure number type conversion with validation
  const ensureNumber = useCallback((value) => {
    if (value === null || value === undefined) return 0;
    if (typeof value === 'number') return value;
    if (typeof value === 'string') {
      const parsed = parseFloat(value);
      return isNaN(parsed) ? 0 : parsed;
    }
    return 0;
  }, []);

  // Number to Words Conversion with caching
  const numberToEnglishSentence = useCallback((number) => {
    console.log('[numberToEnglish] Converting:', number);
    const numbersToWordsLessThanTwenty = [
      "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight",
      "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen",
      "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];
    
    const multiplesOfTen = [
      "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", 
      "Eighty", "Ninety"
    ];

    const convertToWords = (num) => {
      if (num === 0) return "Zero";
      if (num < 20) return numbersToWordsLessThanTwenty[num];
      
      if (num < 100) {
        return multiplesOfTen[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + convertToWords(num % 10) : "");
      }
      
      if (num < 1000) {
        return numbersToWordsLessThanTwenty[Math.floor(num / 100)] +
          " Hundred" +
          (num % 100 !== 0 ? " and " + convertToWords(num % 100) : "");
      }
      
      if (num < 100000) {
        return convertToWords(Math.floor(num / 1000)) +
          " Thousand" +
          (num % 1000 !== 0 ? " " + convertToWords(num % 1000) : "");
      }
      
      return convertToWords(Math.floor(num / 100000)) +
        " Lakh" +
        (num % 100000 !== 0 ? " " + convertToWords(num % 100000) : "");
    };

    const result = convertToWords(Math.abs(Math.floor(number)));
    console.log('[numberToEnglish] Result:', result);
    return result;
  }, []);

  // Process all numeric fields in order data
  const processorderData = useCallback((data) => {
    console.log('[processorderData] Starting data processing');
    
    try {
      // Process products
      const processedProducts = data.products?.map(product => {
        console.log('[processorderData] Processing product:', product);
        return {
          ...product,
          unitPrice: ensureNumber(product.unitPrice),
          quantity: ensureNumber(product.quantity)
        };
      }) || [];

      // Process discount
      const processedDiscount = data.discount ? {
        ...data.discount,
        amount: ensureNumber(data.discount.amount),
        rate: ensureNumber(data.discount.rate)
      } : null;

      // Process taxes
      const processedTaxes = data.taxes?.map(tax => ({
        ...tax,
        amount: ensureNumber(tax.amount),
        rate: ensureNumber(tax.rate)
      })) || [];

      // Process additional charges
      const processedCharges = data.additionalCharges?.map(charge => ({
        ...charge,
        amount: ensureNumber(charge.amount),
        rate: ensureNumber(charge.rate)
      })) || [];

      const processedData = {
        ...data,
        products: processedProducts,
        discount: processedDiscount,
        taxes: processedTaxes,
        additionalCharges: processedCharges,
        total: ensureNumber(data.total),
        subTotal: ensureNumber(data.subTotal),
        receivedAmount: ensureNumber(data.receivedAmount)
      };

      console.log('[processorderData] Processed data:', processedData);
      return processedData;
    } catch (error) {
      console.error('[processorderData] Error:', error);
      throw new Error('Failed to process order data');
    }
  }, [ensureNumber]);

  // Utility Functions
  const getCompleteImageUrl = useCallback((iconUrl) => {
    if (!iconUrl) return null;
    try {
      return iconUrl.startsWith('http') ? iconUrl : `${BUCKET_URL}${iconUrl}`;
    } catch (error) {
      console.error('[getCompleteImageUrl] Error:', error);
      return null;
    }
  }, []);

  const formatWithCommas = useCallback((value) => {
    const numValue = ensureNumber(value);
    return new Intl.NumberFormat('en-IN').format(numValue);
  }, [ensureNumber]);

  const createDate = useCallback((date) => {
    if (!date) return "";
    try {
      const quotDate = new Date(date);
      return quotDate.toLocaleDateString('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (error) {
      console.error('[createDate] Error:', error);
      return "";
    }
  }, []);

  const decimalToEnglish = useCallback((decimalNumber) => {
    if (!decimalNumber) return "";
    try {
      const [integerPart, decimalPart] = decimalNumber.toString().split(".");
      const integerWords = numberToEnglishSentence(parseInt(integerPart));
      const decimalWords = decimalPart ? 
        numberToEnglishSentence(parseInt(decimalPart)) : "";
      return decimalWords ? 
        `${integerWords} decimal ${decimalWords}` : integerWords;
    } catch (error) {
      console.error('[decimalToEnglish] Error:', error);
      return "";
    }
  }, [numberToEnglishSentence]);

  // PDF Generation Methods
  const generatePdf = useCallback(async (html) => {
    if (!html) return;
    try {
        return await OrderPdfService.exportPDF(html, orderData);
    } catch (error) {
        console.error('PDF generation failed:', error);
        throw error;
    }
}, [orderData]);

  const exportPDF = useCallback(async (html) => {
    if (exportInProgress.current || !isMounted.current) {
        console.log('Export already in progress or component unmounted');
        return;
    }

    exportInProgress.current = true;
    setPdfGenerating(true);

    try {
        await OrderPdfService.exportPDF(html, orderData);
        console.log('order PDF generated successfully');
    } catch (error) {
        console.error('order PDF generation failed:', error);
        if (isMounted.current) {
            setError('Failed to export PDF');
        }
    } finally {
        if (isMounted.current) {
            setPdfGenerating(false);
        }
        exportInProgress.current = false;
    }
}, [orderData]);

  // Data Fetching
  useEffect(() => {
    const fetchOrderData  = async () => {
        console.log('[fetchorderData] Starting fetch');
        setIsLoading(true);
        
        try {
          const url = new URL(window.location.href);
          const id = url.pathname.split("/")[2];
          const token = url.searchParams.get("token");
          
          // Add debug logs
          console.log("URL:", window.location.href);
          console.log("Path parts:", url.pathname.split("/"));
          console.log("ID:", id);
          console.log("Token:", token);
      
          const response = await axios.get(
            `https://mapi2.3sigmacrm.com/api/v1/order/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        
        if (!isMounted.current) return;

        const processedData = processorderData(response.data.data);
        setorderData(processedData)
        setCurrencySymbol(
          processedData.products[0]?.currency === "₹" 
            ? "Rs " 
            : processedData.products[0]?.currency || "Rs "
        );
      } catch (error) {
        console.error('[fetchorderData] Error:', error);
        if (isMounted.current) {
          setError('Failed to fetch order data');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchOrderData();
  }, [processorderData]);

  const utils = {
    getCompleteImageUrl,
    createDate,
    formatWithCommas,
    decimalToEnglish,
    generatePdf,
    exportPDF
  };

  if (isLoading) {
    return <div className="loading-container">Loading order data...</div>;
  }

  if (error) {
    return <div className="error-container">Error: {error}</div>;
  }

  console.log('[order] Rendering with data:', {
    hasData: !!orderData._id,
    productsCount: orderData.products.length,
    pdfGenerating
  });

  return (
    <div>
      <OrderPdfContainer
    onExport={(element) => OrderPdfService.exportPDF(element, orderData)}
    orderData={orderData}
>
        <OrderView
          orderData={orderData}
          currencySymbol={currencySymbol}
          utils={utils}
        />
      </OrderPdfContainer>
    </div>
  );
};

export default Order;