import React, { useEffect } from "react";

const savePdfBtn = {
  borderRadius: "9px",
  backgroundColor: "rgba(12,35,87,255)",
  color: "white",
  padding: "12px",
  border: "none",
  cursor: "pointer",
  minWidth: "200px",
  maxWidth: "350px",
};

const toolbar = {
  position: "relative",
};

const toolbarContainer = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const pdfBody = {
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  // height: "90vh",
  // width: 'inherit',
  marginTop: "20px",
};

const container1 = {
  // backgroundColor: '#fff',
  // height: '100vh',
  display: "flex",
  width: "fit-content",
  margin: "auto",
  justifyContent: "center",
};
const container2 = {
  // backgroundColor: '#fff',
  // height: '100vh',
  // display: 'flex',
  // width: 'fit-content',
  margin: "auto",
  // justifyContent: 'center'
};

export default (props) => {
  const bodyRef = React.createRef();
  const generatePdf = () => props.generatePdf(bodyRef.current);
  const exportPDF = () =>
    props.exportPDF(bodyRef.current, props.cardData, props.type);

  useEffect(() => {
    props.cardData?._id && exportPDF();
  }, [props.vcfPath]);
  useEffect(() => {
    props.cardData?._id && exportPDF();
  }, [props.cardData]);

  return (
    <section
      style={props.type === "quotation" ? container2 : container1}
      className="pdf-container"
    >
      <section style={pdfBody} className="pdf-body" ref={bodyRef}>
        {props.children}
      </section>
      <section style={toolbar} className="pdf-toolbar">
        <div style={toolbarContainer}>
          {/* <button className='mr-1' style={savePdfBtn} onClick={exportPDF}>Share</button> */}
          {/* <button className='ml-1' style={savePdfBtn}
            onClick={() => {
              localStorage.removeItem('isBusinessCardDownloaded')
              generatePdf()
            }}>
            Download</button> */}
        </div>
      </section>
    </section>
  );
};
