import axios from "axios";

export const generateOtp = (data) => {
    return axios.post(`${process.env.REACT_APP_WEB_APIURL}v1/auth/generate-otp`, data, { "Content-Type": 'application/json' });
}
export const signIn = (data) => {
    return axios.post(`${process.env.REACT_APP_WEB_APIURL}v1/auth`, data, { "Content-Type": 'application/json' });
}

export const deleteUser = () => {
    let obj = {
        "Content-Type": 'application/json',
        "Authorization": localStorage.getItem('auth_token') || ''
    }
    return axios.delete(`${process.env.REACT_APP_WEB_APIURL}v1/user`, { headers: obj });
}