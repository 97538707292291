import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  createUserIntegration,
  disconnectIntegration,
  getUserIntegration,
  updateUserIntegration,
  getLeadList,
} from "../constants/ApiCall";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import moment from "moment";

import Integration from "../Components/Integration Components/Integration";
import Button from "../Components/Integration Components/Button";
import Header from "../Components/Integration Components/Header";
import Logo from "../images/tradeindia.png";
import { makeStyles } from "@material-ui/core";

function TradeIndia(props) {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [errorText, setErrorText] = useState();
  const [integration, setIntegration] = useState(null);
  const [leadData, setLeadData] = useState([]);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [integrationDate, setIntegrationDate] = useState(new Date());
  const [selectedLeadList, setSelectedLeadList] = useState("");

  useEffect(() => {
    getIntegration();
  }, []);

  const getIntegration = async () => {
    const response = await getUserIntegration();
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "tradeindia"
      );
      getLeadListData();
      if (_integration) {
        setIntegration(_integration);
        setIntegrationDate(_integration.createdAt);
        setSelectedLeadList(_integration.details[0].list);
        setIntegrationDetails(_integration.details[0]);
        setData(_integration.details[0]);
      }
    }
  };
  const getLeadListData = async () => {
    let params = {
      isAscending: false,
      page: 1,
      perPage: 15,
    };
    const response = await getLeadList(params);
    if (response && response.status) {
      setLeadData(response.data);
    } else {
      setLeadData([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingText("Connecting...");
    let payload = {
      key: data.key,
      profileId: data.profileId,
      userId: data.userId,
      ...(selectedLeadList != "" && {
        list: selectedLeadList,
      }),
    };
    const response = await createUserIntegration("tradeindia", [payload]);
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/integration/success`,
      });
    } else {
      setErrorText(response ? response.message : "Error in integration");
    }
  };

  const handleDisconnectIntegration = async () => {
    setLoading(true);
    setLoadingText("Disconnecting...");
    const response = await disconnectIntegration(
      integration?.userIntegrationId
    );
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/disconnect/success`,
      });
    } else {
      NotificationManager.error("Error in integration");
    }
  };
  const handleSelectLeadList = async (lead_id) => {
    setSelectedLeadList(lead_id);
    if (integration) {
      let tempObj = {};
      if (lead_id) {
        tempObj = {
          ...integrationDetails,
          list: lead_id,
        };
      } else {
        tempObj = {
          ...integrationDetails,
        };
      }

      const response = await updateUserIntegration(
        integration?.userIntegrationId,
        tempObj
      );
      if (response && response.status) {
        NotificationManager.success(response.message);
      } else {
        NotificationManager.error("Error in integration");
      }
    }
  };

  const useStyles = makeStyles(() => ({
    container: {
      padding: integration ? "0 18px" : "0 20px",
      "& .form_lbl": {
        fontFamily: "'Gilroy-SemiBold' !important",
      },
      "& button": {
        position: "relative",
        transform: "translateX(-50%)",
        left: "50%",
        width: "330px",
        marginBottom: "30px !important",
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
    },
    input: {
      width: "100%",
      minWidth: "331px",
      height: "39px",
      background: "#FFFFFF",
      borderRadius: "10px",
      border: "none",
      padding: "10px 17px",
      "&::placeholder": {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        color: "rgba(0, 0, 0, 0.5)",
      },
    },
    selectWrap: {
      width: "fit-content",
      height: "40px",
      width: "100%",
      position: "relative",
      "& svg": {
        position: "absolute",
        right: "22px",
        top: "16.5px",
      },
    },
    select: {
      height: "40px",
      padding: "0 15px",
      color: "rgba(0, 0, 0, 0.5)",
      fontWeight: "600",
      appearance: "none",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
    },
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"TradeIndia Integration"}
            logo={<img src={Logo} alt="TradeIndia" width="35" height="35" />}
          />
          <div className={classes.text + " ms-1"}>
            Connect your TradeIndia account and automatically import your
            inquiries
          </div>
          {!integration && (
            <>
              <div className={classes.text + " mt-3 ms-1 mb-3"}>
                How does TradeIndia integration work?
              </div>
              <div className={classes.iframe + " iframe_container"}>
                <iframe
                  width="300"
                  height="150"
                  frameBorder="0"
                  title="youtube"
                  style={{ borderRadius: "10px" }}
                  src="https://www.youtube.com/embed/LY4Yse--Ggo?controls=0"
                ></iframe>
              </div>
            </>
          )}
          {integration && (
            <div className={"mt-4 mb-4 form_lbl " + classes.text}>
              Tradeindia Connected successfully on{" "}
              {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
            </div>
          )}
          <div className="mt-3 form_lbl">UserID</div>
          <input
            type="text"
            className={"mb-2 " + classes.input}
            name="userId"
            onChange={handleChange}
            value={data?.userId}
            placeholder="Enter UserID"
          />
          <div className="mt-2 form_lbl">Profile ID</div>
          <input
            type="text"
            className={"mb-2 " + classes.input}
            name="profileId"
            onChange={handleChange}
            value={data?.profileId}
            placeholder="Enter Profile ID"
          />
          <div className="mt-1 form_lbl">Key</div>
          <input
            type="text"
            className={"mb-2 " + classes.input}
            name="key"
            onChange={handleChange}
            value={data?.key}
            placeholder="Enter Key"
          />
          <p className="error-text">{errorText ? errorText : null}</p>
          <div className="mt-2 form_lbl">Select Custom lead list</div>
          <label className={classes.selectWrap + " mb-5"}>
            <select
              value={selectedLeadList}
              onChange={(e) => handleSelectLeadList(e.target.value)}
              className={classes.select + " mb-5"}
            >
              <option value={""} disabled selected>
                Select list
              </option>
              {leadData.map((item, i) => {
                return (
                  <option key={i} value={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.41 0L6 4.59L10.59 0L12 1.42L6 7.42L0 1.42L1.41 0Z"
                fill="black"
              />
            </svg>
          </label>
          {integration ? (
            <Button
              onClick={handleDisconnectIntegration}
            >
              Disconnect
            </Button>
          ) : (
            <Button
              onClick={onSubmit}
            >
              Connect
            </Button>
          )}
        </div>
      </LoadingOverlay>
      <NotificationContainer />
    </BottomSheet>
  );
}

export default TradeIndia;
