import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import Integration from "../Components/Integration Components/Integration";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  createUserIntegration,
  getUserIntegration,
  getLeadList,
  updateUserIntegration
} from "../constants/ApiCall";
import moment from "moment";

const bottomBtn = {
  backgroundColor: "#3A4B86",
  padding: "10px",
  color: "#FFFFFF",
  borderRadius: "15px",
  textAlign: "center",
  width: "92%",
  fontWeight: "bold",
  fontSize: "20px"
};

const iframeStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "20px",
  borderRadius: "10px",
};

const copyIcon = {
  backgroundColor: "white",
  alignItems: "center",
  borderRadius: "10px",
  paddingRight: "10px",
};

const fb_font1 = {
  fontWeight: "bold",
  fontSize: "18px",
}

function GoogleAds(props) {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [integration, setIntegration] = useState(null);
  const [leadData, setLeadData] = useState([]);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [selectedLeadList, setSelectedLeadList] = useState("");
  const [integrationDate, setIntegrationDate] = useState(new Date());

  useEffect(() => {
    getIntegration();
  }, []);

  const getIntegration = async () => {
    const response = await getUserIntegration();
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "google_lead_form"
      );
      getLeadListData()
      if (_integration) {
        setIntegration(_integration);
        setIntegrationDetails(_integration.details)
        setIntegrationDate(_integration.createdAt)
        setData({
          glusrMobileKey: _integration.details?.glusrMobileKey || "",
          glusrMobile: _integration.details?.glusrMobile || "",
          APIkey: _integration.details?.apiKey || "",
        });
      }
    }
  };
  const getLeadListData = async () => {
    let params = {
      isAscending: false,
      page: 1,
      perPage: 15,
    };
    const response = await getLeadList(params);
    if (response && response.status) {
      setLeadData(response.data)
    } else {
      setLeadData([])
    }
  }
  const onSubmit = async () => {
    let details = {
      api_key: data?.APIkey,
      ...(selectedLeadList != "" && {
        list: selectedLeadList
      }),
    };
    const response = await createUserIntegration("google_lead_form", details);
    if (response && response.status) {
      console.log("response", response);
    }
  };

  const handleSelectLeadList = async (lead_id) => {
    setSelectedLeadList(lead_id)
    if (integration) {
      let tempObj = {}
      if (lead_id) {
        tempObj = {
          ...integrationDetails,
          list: lead_id,
        }
      } else {
        tempObj = {
          ...integrationDetails,
        }
      }

      const response = await updateUserIntegration(integration?.userIntegrationId, tempObj);
      if (response && response.status) {
        NotificationManager.success(response.message);
      } else {
        NotificationManager.error("Error in integration");
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 2]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <div className="container">
          <Integration
            title={"Google Ads leadform"}
            logo={window.location.origin + "/assets/images/GoogleAds.png"}
            isRounded={true}
          />
          <div className="px-3 py-2">
            <div className="font_light">
              Connect your Google Ads leadforms  and automatically import your form Leads instantly .
            </div>
            <div className="mt-4 font_bold">How does Google Ads leadsforms work?</div>
            {/* <textarea rows="3" cols="50" style={inputStyle} className="mt-2" /> */}
            <div style={iframeStyle}>
              <iframe
                width="300"
                height="150"
                frameBorder="0"
                style={{ borderRadius: "10px" }}
                src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0"
              ></iframe>
            </div>
            {integration && (
              <div className="pt-5" style={fb_font1}>
                Google Leadform Connected successfully on {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
              </div>
            )}
            <div className="mt-4 form_lbl">Webhook URL</div>
            <div style={copyIcon}>
              <input
                type="text"
                className="mt-2 inputStyle1"
                name="url"
                onChange={handleChange}
                value={data?.url}
              />
              <img style={{ float: 'right', marginTop: '15px' }} src={window.location.origin + "/assets/images/copy.png"} />
            </div>

            <div className="mt-4 form_lbl">API key</div>
            <div style={copyIcon}>
              <input
                type="text"
                className="mt-2 inputStyle1"
                name="APIkey"
                onChange={handleChange}
                value={data?.APIkey}
              />
              <img style={{ float: 'right', marginTop: '15px' }} src={window.location.origin + "/assets/images/copy.png"} />
            </div>
            <div className="mt-4 form_lbl">Select Custom lead list</div>
            <select
              value={selectedLeadList}
              onChange={(e) => handleSelectLeadList(e.target.value)}
            >
              <option value={""}>Default lead</option>
              {leadData.map((item, i) => {
                return (
                  <option key={i} value={item._id}>{item.name}</option>
                )
              })}
            </select>
            <div style={bottomBtn} onClick={onSubmit} className="btn">
              <img
                src={window.location.origin + "/assets/images/GoogleAds.png"}
              />
              &nbsp;Connect Google leadform
            </div>
          </div>
        </div>
      </LoadingOverlay>
      <NotificationContainer />
    </BottomSheet>
  );
}

export default GoogleAds;
