/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  createUserIntegration,
  disconnectIntegration,
  getUserIntegration,
  importFacebookPastLeads,
  getLeadList,
  updateUserIntegration,
} from "../constants/ApiCall";
import queryString from "query-string";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Alert from "@material-ui/lab/Alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../Components/Integration Components/Header";
import Button from "../Components/Integration Components/Button";
import Integration from "../Components/Integration Components/Integration";

function Facebook(props) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [integration, setIntegration] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [pastLeadsDuration, setPastLeadsDuration] = useState(null);
  const [showAlert, toggleAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [leadData, setLeadData] = useState([]);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [integrationDate, setIntegrationDate] = useState(new Date());

  useEffect(() => {
    let queryParams = queryString.parse(props.location.search);
    if (queryParams["accessToken"]) {
      handleSavePress(queryParams);
      //window.handleFacebookLogin(queryParams["accessToken"], handleSavePress);
    }
  }, []);

  useEffect(() => {
    getIntegration();
  }, []);

  const getIntegration = async () => {
    setLoading(true);
    let queryParams = queryString.parse(props.location.search);
    const response = await getUserIntegration(queryParams["token"]);
    setLoading(false);
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "facebook"
      );
      getLeadListData();
      if (_integration) {
        setIntegrationDetails(_integration.details);
        setIntegration(_integration);
        setIntegrationDate(_integration.createdAt);
      }
    }
  };
  const getLeadListData = async () => {
    let params = {
      isAscending: false,
      page: 1,
      perPage: 15,
    };
    const response = await getLeadList(params);
    if (response && response.status) {
      setLeadData(response.data);
    } else {
      setLeadData([]);
    }
  };
  const handlePastLeads = async () => {
    if (!pastLeadsDuration) {
      toggleAlert(true);
      setAlertType("error");
      setAlertMessage(
        "Please select duration for which you want to fetch past leads"
      );
      return;
    }
    try {
      setLoading(true);
      let queryParams = queryString.parse(props.location.search);
      const response = await importFacebookPastLeads(
        pastLeadsDuration,
        queryParams["token"]
      );
      NotificationManager.success(response.message);
      toggleAlert(false);
      setLoading(false);
      setLoadingText("Pulling Facebook Past Leads");
    } catch (error) {
      setLoading(false);
      console.log("Error while importing leads", error.message);
      alert(error?.message || "Error while importing leads");
    }
  };

  const handleSavePress = async (data) => {
    try {
      setLoadingText("Linking With 3Sigma");
      let payload = {
        fbUserId: data.fb_user_id,
        fbUserAccessToken: data.accessToken,
      };
      let queryParams = queryString.parse(props.location.search);
      console.log(queryParams["token"]);
      const response = await createUserIntegration(
        "facebook",
        payload,
        queryParams["token"]
      );
      console.log(response, "page response");
      setLoading(false);
      if (response && response.status) {
        props.history.push({
          pathname: `/integration/success`,
        });
      }
    } catch (error) {
      alert("Error in saving integration.");
      setLoading(false);
    }
  };

  const handleDisconnectIntegration = async () => {
    setLoading(true);
    setLoadingText("Disconnecting...");
    let queryParams = queryString.parse(props.location.search);
    const response = await disconnectIntegration(
      integration?.userIntegrationId,
      queryParams["token"]
    );
    setLoading(false);
    if (response && response.status) {
      props.history.push({
        pathname: `/disconnect/success`,
      });
    } else {
      alert("Error  in integration");
    }
  };

  const handleSelectLeadList = (id) => {
    handleUpdateUserIntegration(id);
  };
  const handleUpdateUserIntegration = async (lead_id) => {
    let tempObj = {};
    if (lead_id) {
      tempObj = {
        ...integrationDetails,
        list: lead_id,
      };
    } else {
      tempObj = {
        ...integrationDetails,
      };
    }

    const response = await updateUserIntegration(
      integration?.userIntegrationId,
      tempObj
    );
    if (response && response.status) {
      NotificationManager.success(response.message);
    } else {
      NotificationManager.error("Error in integration");
    }
  };

  const useStyles = makeStyles(() => ({
    container: {
      padding: integration ? "0 21px" : "0 15px",
      "& button": {
        width: "330px",
        position: "relative",
        transform: "translateX(-50%)",
        left: "50%",
        marginBottom: "30px !important",
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
      margin: integration ? "0 10px" : "0",
    },
    iframe: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "20px",
      borderRadius: "10px",
    },
    selectWrap: {
      width: "fit-content",
      height: "40px",
      width: "100%",
      position: "relative",
      "& svg": {
        position: "absolute",
        right: "22px",
        top: "16.5px",
      },
    },
    select: {
      height: "40px",
      padding: "0 15px",
      color: "rgba(0, 0, 0, 0.5)",
      fontWeight: "600",
      appearance: "none",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
    },
    leadButton: {
      backgroundColor: "#4C88D3",
      padding: "10px",
      border: "#4C88D3",
      color: "#FFFFFF",
      borderRadius: "15px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "20px",
      justifyContent: "center",
      display: "flex",
      margin: "19px auto 50px auto",
      width: "211px",
      height: "48px",
      fontFamily: "Inter",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "28px",
    },
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      {showAlert && <Alert severity={alertType}>{alertMessage}</Alert>}
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title="Facebook integration"
            logo={
              <svg
                width="29"
                height="27"
                viewBox="0 0 29 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 0C6.52499 0 0 6.08584 0 13.5813C0 20.3584 5.30699 25.9834 12.238 27V17.512H8.55499V13.5813H12.238V10.5858C12.238 7.18374 14.3985 5.31325 17.719 5.31325C19.2995 5.31325 20.9525 5.57078 20.9525 5.57078V8.91868H19.1255C17.3275 8.91868 16.762 9.96235 16.762 11.0331V13.5813H20.793L20.1405 17.512H16.762V27C20.1788 26.4956 23.2902 24.8659 25.5344 22.4052C27.7786 19.9445 29.0077 16.8149 29 13.5813C29 6.08584 22.475 0 14.5 0Z"
                  fill="#4C88D3"
                />
              </svg>
            }
          />
          <div className={classes.text}>
            Instantly receive new leads by connecting your Facebook account.
          </div>
          {integration ? (
            <div className={classes.text + ` ${integration ? "pt-4" : "pt-5"}`}>
              Facebook Connected successfully on
              {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
            </div>
          ) : (
            <>
              <div
                className={classes.text + " center_text"}
                style={{ margin: "45px 0 25px 0", textAlign: "center" }}
              >
                How does facebook integration work?
              </div>
              <div className={classes.iframe + " iframe_container"}>
                <iframe
                  width="300"
                  height="150"
                  frameBorder="0"
                  title="youtube"
                  style={{ borderRadius: "10px" }}
                  src="https://www.youtube.com/embed/t0ljrZqU_dI?controls=0"
                ></iframe>
              </div>
            </>
          )}
          {integration ? (
            <>
              <Grid item xs={12} className="mt-5">
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className="form_lbl"
                >
                  Select lead list
                </InputLabel>
                <label className={classes.selectWrap}>
                  <select
                    onChange={(e) => handleSelectLeadList(e.target.value)}
                    className={classes.select}
                  >
                    <option>Select list </option>
                    <option value={""}>Default lead</option>
                    {leadData.map((item, i) => {
                      return (
                        <option key={i} value={item._id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0L6 4.59L10.59 0L12 1.42L6 7.42L0 1.42L1.41 0Z"
                      fill="black"
                    />
                  </svg>
                </label>
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className="form_lbl mt-4 pt-2"
                >
                  Import past leads
                </InputLabel>
                <label className={classes.selectWrap}>
                  <select
                    onChange={(e) => setPastLeadsDuration(e.target.value)}
                    className={classes.select}
                  >
                    <option>None</option>
                    <option value={"24h"}>Last 24 hours leads</option>
                    <option value={"7d"}>Last 7 days leads</option>
                    <option value={"30d"}>Last 30 days leads</option>
                    <option value={"90d"}>Last 90 days leads</option>
                  </select>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0L6 4.59L10.59 0L12 1.42L6 7.42L0 1.42L1.41 0Z"
                      fill="black"
                    />
                  </svg>
                </label>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.leadButton} onClick={handlePastLeads}>
                  Import past leads
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={handleDisconnectIntegration}
                >
                  Disconnect
                </Button>
              </Grid>
            </>
          ) : (
            <Button
              onClick={() => {
                props.history.push({
                  pathname: `/login/facebook`,
                });
              }}
            >
              Connect
            </Button>
          )}
        </div>
      </LoadingOverlay>
      <NotificationContainer />
    </BottomSheet>
  );
}

export default Facebook;
