import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

function LocationListener() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      console.log("Location changed:", location.pathname);
      console.log("Action:", action);

      if (location.pathname === "/disconnect/success") {
        postMessage("disconnect");
      } else if (location.pathname === "/integration/success") {
        postMessage("connected");
      } else if (location.pathname === "/login/facebook") {
        postMessage("facebook");
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const postMessage = (message) => {
    window?.parent?.postMessage(
      {
        message,
      },
      "*"
    );
  };

  return null; // This component does not render anything
}

export default LocationListener;
