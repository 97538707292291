import { makeStyles } from "@material-ui/core";
import React from "react";

const Integration = ({ title, logo, width }) => {

  const useStyles = makeStyles(() => ({
    integration_type: {
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      margin: "0 auto 35px auto",
      gap: "16px",
      "& h2": {
        fontFamily: "Gilroy-Semibold !important",
        fontWeight: "800",
        fontSize: "20px",
      },
    }
  }));

  const classes = useStyles();


  return (
    <>
      <div className={classes.integration_type + " integration_type"}>
        {logo}
        <h2>{title}</h2>
      </div>
    </>
  );
};

export default Integration;
