import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
    createUserIntegration,
    disconnectIntegration,
    getUserIntegration,
} from "../constants/ApiCall";

import Integration from "../Components/Integration Components/Integration";
const bottomBtn = {
    backgroundColor: "#3A4B86",
    padding: "10px",
    color: "#FFFFFF",
    borderRadius: "15px",
    textAlign: "center",

    fontWeight: "500",
    fontSize: "20px",
    justifyContent: "center",
    alignItems: "center",
    bottom: "20px",
};

const inputStyle = {
    borderRadius: "10px",
    height: "40px",
    border: "none",
    outline: "none",
    width: "99%",
};

const iframeStyle = {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
    borderRadius: "10px",
};

const roundIcon = {
    borderRadius: "50%",
  };

function Wati(props) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("Loading");
    const [errorText, setErrorText] = useState();
    const [integration, setIntegration] = useState(null);
    useEffect(() => {
        getIntegration();
    }, []);
    const getIntegration = async () => {
        const response = await getUserIntegration();
        if (response && response.status) {
            const _integration = response.data.find(
                (_i) => _i.integrationKey === "wati"
            );
            if (_integration) {
                setIntegration(_integration);
                setData(_integration.details[0]);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setLoadingText("Connecting...");
        let payload = {
            watiApiEndpoint: data.watiApiEndpoint,
            token: data.token,
            // webhookUrl: data.webhookUrl,
        };
        const response = await createUserIntegration("wati", payload);
        setLoading(false);
        if (response && response.status) {
            props.history.push({
                pathname: `/integration/success`,
            });
        } else {
            setErrorText(response ? response.message : "Error in integration");
        }
    };

    const handleDisconnectIntegration = async () => {
        setLoading(true);
        setLoadingText("Disconnecting...");
        const response = await disconnectIntegration(
            integration?.userIntegrationId
        );
        setLoading(false);
        if (response && response.status) {
            props.history.push({
                pathname: `/disconnect/success`,
            });
        } else {
            alert("Error  in integration");
        }
    };

    return (
        <BottomSheet
            open={true}
            snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 0.5]}
            defaultSnap={({ lastSnap, snapPoints }) =>
                lastSnap ?? Math.max(...snapPoints)
            }
        >
            <LoadingOverlay active={loading} spinner text={loadingText}>
                <div className="container">
                    <Integration
                        title={"Wati Integration"}
                        logo={window.location.origin + "/assets/images/wati.png"}
                        width={30}
                        isRounded
                    />
                    <div className="px-3 py-2">
                        <div>
                            Now send Whatsapp business API messages directly from 3sigma CRM.You will be able to see broadcast and track who opens your messages.
                        </div>
                        <div className="mt-3">How does Wati integration work?</div>
                        {/* <textarea rows="3" cols="50" style={inputStyle} className="mt-2" /> */}
                        <div style={iframeStyle}>
                            <iframe
                                width="300"
                                height="150"
                                frameBorder="0"
                                borderRadius="5"
                                title="youtube"
                                style={{ borderRadius: "10px" }}
                                src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0"
                            ></iframe>
                        </div>
                        <div className="mt-2  ">Wati token</div>
                        <input
                            type="text"
                            style={inputStyle}
                            className="mt-2 mb-2"
                            name="token"
                            onChange={handleChange}
                            value={data?.token}
                        />
                        <div className="mt-1  ">Api URL</div>
                        <input
                            type="text"
                            style={inputStyle}
                            className="mt-2 mb-2"
                            name="watiApiEndpoint"
                            onChange={handleChange}
                            value={data?.watiApiEndpoint}
                        />
                        {/* <div className="mt-1">Webhook URL</div>
                        <input
                            type="text"
                            style={inputStyle}
                            className="mt-2 mb-2"
                            name="webhookUrl"
                            onChange={handleChange}
                            value={data?.webhookUrl}
                        /> */}
                        <p className="error-text">{errorText ? errorText : null}</p>
                        {integration ? (
                            <div
                                style={bottomBtn}
                                onClick={handleDisconnectIntegration}
                                className="btn"
                            >
                                <img
                                    src={window.location.origin + "/assets/images/wati.png"}
                                    alt="Wati"
                                    width={40}
                                    style={roundIcon}
                                />
                                &nbsp;Disconnect
                            </div>
                        ) : (
                            <div style={bottomBtn} onClick={onSubmit} className="btn">
                                <img
                                    src={window.location.origin + "/assets/images/wati.png"}
                                    alt="Wati"
                                    width={40}
                                    style={roundIcon}
                                />
                                &nbsp;Connect Wati
                            </div>
                        )}
                    </div>
                </div>
            </LoadingOverlay>
        </BottomSheet>
    );
}

export default Wati;
