import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  createUserIntegration,
  getUserIntegration,
  getLeadList,
  updateUserIntegration,
} from "../constants/ApiCall";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import moment from "moment";

import Integration from "../Components/Integration Components/Integration";
import Button from "../Components/Integration Components/Button";
import Header from "../Components/Integration Components/Header";
import Logo from "../images/99acres.jpeg";
import { makeStyles } from "@material-ui/core";

function Acres() {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [integration, setIntegration] = useState(null);
  const [leadData, setLeadData] = useState([]);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [copyURL, setCopyURL] = useState("");
  const [integrationDate, setIntegrationDate] = useState(new Date());

  useEffect(() => {
    connectAcres();
  }, []);

  const connectAcres = async () => {
    const response = await createUserIntegration("99acres");
    if (response && response.status) {
      getIntegration();
    }
  };

  const getIntegration = async () => {
    const response = await getUserIntegration();
    if (response && response.status) {
      const _integration = response.data.find(
        (_i) => _i.integrationKey === "99acres"
      );
      getLeadListData();

      if (_integration) {
        setIntegration(_integration);
        setIntegrationDetails(_integration.details);
        setIntegrationDate(_integration.createdAt);

        let content = `Please share my 99acres.com leads with 3Sigma CRM.

My details          
CRM service provider name :3sigma 
Working Endpoint :${process.env.REACT_APP_DEV_API_WEBHOOK_URL}${"acres"}/${
          _integration.details.apiKey
        }
Sample URL :${process.env.REACT_APP_DEV_API_WEBHOOK_URL}${"acres"}/${
          _integration.details.apiKey
        }
API Key :no auth needed
URL Parameters :https://www.postman.com/threesigmacrm/workspace/3sigma-api-docs/request/20352867-eea2339a-ca61-4d11-9295-f1d510e181cb
`;

        setData({
          APIkey: content,
        });
        setCopyURL(content);
      } else {
        setData({ APIkey: "" });
      }
    }
  };

  const getLeadListData = async () => {
    let params = {
      isAscending: false,
      page: 1,
      perPage: 15,
    };
    const response = await getLeadList(params);
    if (response && response.status) {
      setLeadData(response.data);
    } else {
      setLeadData([]);
    }
  };

  const handleSelectLeadList = async (lead_id) => {
    setLoadingText("Loading");
    let tempObj = {};
    if (lead_id) {
      tempObj = {
        ...integrationDetails,
        list: lead_id,
      };
    } else {
      tempObj = {
        ...integrationDetails,
      };
    }

    const response = await updateUserIntegration(
      integration?.userIntegrationId,
      tempObj
    );
    if (response && response.status) {
      NotificationManager.success(response.message);
    } else {
      NotificationManager.error("Error in integration");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const useStyles = makeStyles(() => ({
    container: {
      padding: "0 15px",
      "& .form_lbl": {
        fontFamily: "'Gilroy-SemiBold' !important",
      },
      "& button": {
        width: "330px",
        position: "relative",
        marginBottom: "50px !important",
        marginTop: "70px !important",
        display: "flex",
        alignItems: "center",
        paddingLeft: "33px",
        gap: "9px",
      },
    },
    text: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "18px",
      color: "#000000",
      padding: "0 7px",
    },
    selectWrap: {
      width: "fit-content",
      height: "40px",
      width: "100%",
      position: "relative",
      "& svg": {
        position: "absolute",
        right: "22px",
        top: "16.5px",
      },
    },
    select: {
      height: "40px",
      padding: "0 15px",
      color: "rgba(0, 0, 0, 0.5)",
      fontWeight: "600",
      appearance: "none",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
    },
  }));

  const classes = useStyles();

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"99Acres.com Integration"}
            logo={<img src={Logo} alt="99Acres" width="37" height="37" />}
          />
          <div className={classes.text}>
            Connect your 99Acres.com account and automatically receive your
            inquiries in 3Sigma CRM
          </div>
          <div className={classes.text + " mt-4"}>
            Share the below message to your 99acres.comrelationship manager.
            Once activated you will start receiving 99acres.com leads
            automatically in 3sigma CRM
          </div>
          {/* <div className="mt-4">UserID</div>
            <input
              type="text"
              style={inputStyle}
              className="mt-2"
              name="userId"
              onChange={handleChange}
              value={data?.userId}
            /> */}
          {/* <div className="mt-1">Key</div>
            <input
              type="text"
              style={inputStyle}
              className="mt-2"
              name="key"
              onChange={handleChange}
              value={data?.key}
            />
            */}
          {/*integration && (
            <div className={"pt-5 " + classes.text}>
              99Acres Connected successfully on{" "}
              {moment(integrationDate).format("DD MMM YYYY hh:mm A")}
            </div>
          )*/}
          <div className="copyIcon mt-3">
            {/* <input
                type="text"
                className="mt-2 mb-2 inputStyle"
                name="url"
                value={url}
              /> */}
            <textarea
              rows="8"
              cols="80"
              className={"mt-2 inputStyle font-bold"}
              onChange={handleChange}
              style={{ padding: "10px 17px", fontWeight: "700" }}
              name="APIkey"
              value={data.APIkey}
            />
            <CopyToClipboard text={data.APIkey} onCopy={() => setCopied(true)}>
              {copied ? (
                <img
                  src={window.location.origin + "/assets/images/checked.ico"}
                  alt="copy-icon"
                  className="success_tick"
                />
              ) : (
                <img
                  src={window.location.origin + "/assets/images/copy.png"}
                  alt="copy-icon"
                  style={{ float: "right", marginTop: "15px" }}
                />
              )}
            </CopyToClipboard>
          </div>
          <div className="mt-3 form_lbl">Select Custom lead list</div>
          <label className={classes.selectWrap}>
            <select
              onChange={(e) => handleSelectLeadList(e.target.value)}
              className={classes.select + " mb-5"}
            >
              <option value={""} disabled selected>
                Select list
              </option>
              {leadData.map((item, i) => {
                return (
                  <option key={i} value={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.41 0L6 4.59L10.59 0L12 1.42L6 7.42L0 1.42L1.41 0Z"
                fill="black"
              />
            </svg>
          </label>

          <a
            href={`https://api.whatsapp.com/send?phone=&text=${copyURL}`}
            target="_blank"
          >
            <Button
            // onClick={connectAcres}
            >
              <svg
                width="30"
                height="26"
                viewBox="0 0 30 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0603 0C6.82914 0 0.120603 5.785 0.120603 12.883C0.120603 15.158 0.81407 17.368 2.11055 19.318L0 26L7.91457 24.206C10.1005 25.233 12.5578 25.779 15.0603 25.779C23.2915 25.779 30 19.994 30 12.896C30 9.451 28.4472 6.214 25.6281 3.783C22.809 1.339 19.0553 0 15.0603 0ZM15.0754 2.171C18.392 2.171 21.4975 3.289 23.8492 5.317C26.1859 7.345 27.4824 10.036 27.4824 12.896C27.4824 18.798 21.9045 23.595 15.0603 23.595C12.8291 23.595 10.6432 23.088 8.74372 22.1L8.29146 21.879L3.58794 22.945L4.8392 18.993L4.53769 18.577C3.30151 16.9 2.63819 14.911 2.63819 12.883C2.65327 6.981 8.21608 2.171 15.0754 2.171ZM9.76884 6.929C9.52764 6.929 9.1206 7.007 8.77387 7.332C8.44221 7.657 7.46231 8.45 7.46231 10.023C7.46231 11.609 8.80402 13.13 8.96985 13.351C9.1809 13.572 11.6231 16.822 15.3769 18.2C16.2663 18.551 16.9598 18.746 17.5025 18.889C18.392 19.136 19.206 19.097 19.8543 19.019C20.5779 18.928 22.0553 18.239 22.3719 17.485C22.6884 16.731 22.6884 16.094 22.598 15.951C22.4925 15.821 22.2513 15.743 21.8744 15.6C21.4975 15.418 19.6583 14.638 19.3266 14.534C18.9799 14.43 18.7688 14.378 18.4824 14.69C18.2412 15.015 17.5176 15.743 17.3065 15.951C17.0804 16.172 16.8693 16.198 16.5075 16.042C16.1156 15.873 14.9095 15.535 13.4925 14.443C12.3769 13.585 11.6382 12.532 11.4121 12.207C11.2312 11.895 11.397 11.7 11.5779 11.557C11.7437 11.414 11.9849 11.18 12.1357 10.985C12.3317 10.803 12.392 10.66 12.5126 10.452C12.6332 10.231 12.5729 10.049 12.4824 9.893C12.392 9.75 11.6382 8.138 11.3216 7.501C11.0201 6.877 10.7186 6.955 10.4774 6.942C10.2663 6.942 10.0251 6.929 9.76884 6.929Z"
                  fill="#25D366"
                />
              </svg>
              Share on Whatsapp
            </Button>
          </a>
        </div>
      </LoadingOverlay>
      <NotificationContainer />
    </BottomSheet>
  );
}

export default Acres;
