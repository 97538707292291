import React, { useEffect, useState, useCallback } from "react";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  createUserIntegration,
  disconnectIntegration,
  getUserIntegration,
} from "../constants/ApiCall";
import queryString from "query-string";

import Integration from "../Components/Integration Components/Integration";
import Logo from "../images/operator_logo.svg";
import { makeStyles } from "@material-ui/core";
import Header from "../Components/Integration Components/Header";
import Button from "../Components/Integration Components/Button";

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    maxWidth: '500px',
    margin: '0 auto',
  },
  input: {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '8px', // This line controls the corner rounding
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  errorText: {
    color: 'red',
    marginTop: '10px',
  },
}));

function OperatorIntegration(props) {
  const [open, setOpen] = useState(true);
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [errorText, setErrorText] = useState("");
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [integrationId, setIntegrationId] = useState(null);

  const classes = useStyles({ isIntegrated });

  const getIntegration = useCallback(async () => {
    console.log("getIntegration called");
    setLoading(true);
    setLoadingText("Fetching integration status...");
    try {
      let queryParams = queryString.parse(props.location.search);
      console.log("Query params:", queryParams);
      const response = await getUserIntegration(queryParams["token"]);
      console.log("User Integration Response:", response);
      if (response && response.status) {
        const operatorIntegration = response.data.find(
          (i) => i.integrationKey === "my_operator"
        );
        console.log("Operator Integration:", operatorIntegration);
        if (operatorIntegration && operatorIntegration.details) {
          setIsIntegrated(true);
          setIntegrationId(operatorIntegration.userIntegrationId);
          setApiKey(operatorIntegration.details.apiKey || "");
        } else {
          setIsIntegrated(false);
          setApiKey("");
        }
      }
    } catch (error) {
      console.error("Error fetching integration:", error);
      setErrorText("Failed to fetch integration status. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [props.location.search]);

  useEffect(() => {
    console.log("Component mounted or location.search changed");
    getIntegration();
  }, [getIntegration]);

  const handleChange = (e) => {
    console.log("API Key changed:", e.target.value);
    setApiKey(e.target.value);
    setErrorText("");
  };

  const validateApiKey = (key) => {
    console.log("Validating API Key:", key);
    if (!key || key.length < 10) {
      setErrorText("Please enter a valid API key (at least 10 characters)");
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit button clicked");
    if (!validateApiKey(apiKey)) return;

    setLoading(true);
    setLoadingText("Connecting...");
    try {
      const integrationData = {
        integrationKey: "my_operator",
        details: {
          apiKey: apiKey
        }
      };
      console.log("Integration data being sent:", integrationData);
      const response = await createUserIntegration(integrationData);
      console.log("Create Integration Response:", response);
      if (response && response.status) {
        setIsIntegrated(true);
        setIntegrationId(response.data.userIntegrationId);
        props.history.push({ pathname: `/integration/success` });
      } else {
        throw new Error(response ? response.message : "Error in integration");
      }
    } catch (error) {
      console.error("Error creating integration:", error);
      setErrorText(error.message || "An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnectIntegration = async () => {
    console.log("Disconnect button clicked");
    if (!window.confirm("Are you sure you want to disconnect the MyOperator integration?")) {
      return;
    }

    setLoading(true);
    setLoadingText("Disconnecting...");
    try {
      console.log("Disconnecting integration with ID:", integrationId);
      const response = await disconnectIntegration(integrationId);
      console.log("Disconnect Integration Response:", response);
      if (response && response.status) {
        setIsIntegrated(false);
        setApiKey("");
        setIntegrationId(null);
        props.history.push({ pathname: `/disconnect/success` });
      } else {
        throw new Error("Error in disconnecting integration");
      }
    } catch (error) {
      console.error("Error disconnecting integration:", error);
      setErrorText(error.message || "An unexpected error occurred while disconnecting. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  console.log("Render - Current integration status:", isIntegrated);
  console.log("Render - Current API Key:", apiKey);

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={classes.container}>
          <Integration
            title={"MyOperator Integration"}
            logo={<img src={Logo} alt="Operator" width="42" height="39" />}
          />
          <div className={classes.text}>
          Connect MyOperator with 3sigma CRM for automatic call logging, recordings, and lead capture.
          </div>
          <div className={`${classes.text} mt-5 d-flex align-items-center justify-content-center gap-1`}>
            <a
              href="https://help.3sigmacrm.com/topics/integrations"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "inherit" }}
            >
              How does MyOperator integration work?
              <svg
                width="20"
                height="14"
                viewBox="0 0 20 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 10L13.19 7L8 4V10ZM19.56 2.17C19.69 2.64 19.78 3.27 19.84 4.07C19.91 4.87 19.94 5.56 19.94 6.16L20 7C20 9.19 19.84 10.8 19.56 11.83C19.31 12.73 18.73 13.31 17.83 13.56C17.36 13.69 16.5 13.78 15.18 13.84C13.88 13.91 12.69 13.94 11.59 13.94L10 14C5.81 14 3.2 13.84 2.17 13.56C1.27 13.31 0.69 12.73 0.44 11.83C0.31 11.36 0.22 10.73 0.16 9.93C0.0900001 9.13 0.0599999 8.44 0.0599999 7.84L0 7C0 4.81 0.16 3.2 0.44 2.17C0.69 1.27 1.27 0.69 2.17 0.44C2.64 0.31 3.5 0.22 4.82 0.16C6.12 0.0899998 7.31 0.0599999 8.41 0.0599999L10 0C14.19 0 16.8 0.16 17.83 0.44C18.73 0.69 19.31 1.27 19.56 2.17Z"
                  fill="#9F1212"
                />
              </svg>
            </a>
          </div>
          <div className="mt-4 form_lbl">MyOperator API Key</div>
          <input
            type="text"
            className={`mt-1 mb-2 ${classes.input}`}
            placeholder="Enter your API key"
            name="apiKey"
            onChange={handleChange}
            value={apiKey}
            disabled={isIntegrated}
          />
          {errorText && <p className={classes.errorText}>{errorText}</p>}
          {isIntegrated ? (
            <Button onClick={handleDisconnectIntegration}>Disconnect</Button>
          ) : (
            <Button onClick={onSubmit}>Connect</Button>
          )}
        </div>
      </LoadingOverlay>
    </BottomSheet>
  );
}

export default OperatorIntegration;