import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { BottomSheet } from "react-spring-bottom-sheet";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

import Integration from "../Components/Integration Components/Integration";
import { getLinkedInTokens, disconnectIntegration, getIntegration } from "../constants/ApiCall";
import { API_URL } from "../constants/utils";
import { LinkedInClientService } from "../constants/LinkedIn_client_service";

import Header from "../Components/Integration Components/Header";
import Button from "../Components/Integration Components/Button";
import Logo from "../images/linkedin_logo.png";

const useStyles = makeStyles(() => ({
  container: {
    padding: "0 13px 0 17px",
    "& .integration_type": {
      gap: "12px",
    },
    "& button": {
      width: "330px",
      position: "relative",
      marginBottom: "20px !important",
      marginTop: "20px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "9px"
    },
  },
  text: {
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#000000",
    margin: "0 0 15px 0",
  },
  iframe: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
  },
  status: {
    fontWeight: "bold",
    marginBottom: "15px",
    color: "#4caf50",
  },
  error: {
    color: "#f44336",
    marginBottom: "15px",
  }
}));

function LinkedInIntegration() {
  const [open, setOpen] = useState(true);
  const [integration, setIntegration] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [error, setError] = useState("");
  const { user_id } = useParams();

  const classes = useStyles();

  useEffect(() => {
    checkIntegrationStatus();
  }, []);

  const checkIntegrationStatus = async () => {
    try {
      setLoading(true);
      setLoadingText("Checking integration status...");
      const response = await getIntegration('linkedin', localStorage.getItem('token'));
      setIntegration(response.isConnected);
    } catch (error) {
      console.error("Error checking LinkedIn integration status:", error);
      setError("Failed to check integration status. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleLinkedInAuth = async () => {
    try {
      setLoading(true);
      setLoadingText("Connecting to LinkedIn...");
      setError("");

      const authUrl = `${LinkedInClientService.authorizationUrl}?response_type=code&client_id=${LinkedInClientService.clientId}&redirect_uri=${encodeURIComponent(LinkedInClientService.redirectUri)}&scope=${encodeURIComponent(LinkedInClientService.scope)}`;
      
      const authWindow = window.open(authUrl, '_blank', 'width=600,height=600');

      window.addEventListener('message', async (event) => {
        if (event.origin !== window.location.origin) return;

        const { code } = event.data;
        if (code) {
          try {
            const tokens = await axios.post(LinkedInClientService.tokenUrl, {
              grant_type: 'authorization_code',
              code,
              client_id: LinkedInClientService.clientId,
              client_secret: LinkedInClientService.clientSecret,
              redirect_uri: LinkedInClientService.redirectUri,
            });

            await getLinkedInTokens({
              userId: user_id,
              tokens: {
                access_token: tokens.data.access_token,
                expires_in: tokens.data.expires_in,
                refresh_token: tokens.data.refresh_token,
              },
            });

            setIntegration(true);
            setLoadingText("LinkedIn connected successfully!");
          } catch (error) {
            console.error("Error exchanging code for tokens:", error);
            setError("Failed to connect LinkedIn. Please try again.");
          }
        }
        setLoading(false);
        if (authWindow) authWindow.close();
      });
    } catch (error) {
      console.error("LinkedIn authentication error:", error);
      setError("An error occurred during authentication. Please try again.");
      setLoading(false);
    }
  };

  const handleDisconnect = async () => {
    try {
      setLoading(true);
      setLoadingText("Disconnecting LinkedIn...");
      setError("");
      await disconnectIntegration('linkedin', localStorage.getItem('token'));
      setIntegration(false);
      setLoadingText("LinkedIn disconnected successfully!");
    } catch (error) {
      console.error("Error disconnecting LinkedIn:", error);
      setError("Failed to disconnect LinkedIn. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchLinkedInLeads = async () => {
    try {
      setLoading(true);
      setLoadingText("Fetching LinkedIn leads...");
      setError("");
      const response = await axios.get(`${API_URL}/linkedin/fetch-leads/${user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setLoadingText(`Successfully fetched ${response.data.leadsCount} leads from LinkedIn!`);
    } catch (error) {
      console.error("Error fetching LinkedIn leads:", error);
      setError("Failed to fetch LinkedIn leads. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <BottomSheet
      open={open}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 1]}
      defaultSnap={({ lastSnap, snapPoints }) =>
        lastSnap ?? Math.max(...snapPoints)
      }
    >
      <LoadingOverlay active={loading} spinner text={loadingText}>
        <Header setOpen={setOpen} />
        <div className={"container integration_container " + classes.container}>
          <Integration
            title={"LinkedIn Integration"}
            logo={
              <img
                src={Logo}
                alt="LinkedIn"
                width="40"
                height="40"
                style={{ borderRadius: "50%" }}
              />
            }
          />
          <div className={classes.text}>
            Connect your LinkedIn account to automatically fetch ad leads and sync them with your CRM. This integration streamlines your lead management process and keeps your CRM up-to-date with the latest LinkedIn ad leads.
          </div>
          {error && <div className={classes.error}>{error}</div>}
          {integration && (
            <div className={classes.status}>
              LinkedIn is connected successfully!
            </div>
          )}
          <div className={classes.text}>
            How does LinkedIn ad leads integration work?
          </div>
          <div className={classes.iframe}>
            <iframe
              width="300"
              height="150"
              frameBorder="0"
              title="LinkedIn Integration Tutorial"
              style={{ borderRadius: "10px" }}
              src="https://www.youtube.com/embed/dQw4w9WgXcQ?controls=0"
            ></iframe>
          </div>
          {integration ? (
            <>
              <Button onClick={fetchLinkedInLeads}>
                Fetch LinkedIn Leads
              </Button>
              <Button onClick={handleDisconnect}>
                Disconnect LinkedIn
              </Button>
            </>
          ) : (
            <Button onClick={handleLinkedInAuth}>
              Connect LinkedIn
            </Button>
          )}
        </div>
      </LoadingOverlay>
    </BottomSheet>
  );
}

export default LinkedInIntegration;