import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import QuotationView from "./QuotationView";
import QuotationPdf from "../Components/QuotationPdfService";
import PdfContainer from "../Components/PdfContainer";
import QuotationPdfContainer from "../Components/QuotationPdfContainer";
import html2pdf from "html2pdf.js";
import "./quotation.module.css";
import "./custom.module.css";


const BUCKET_URL = process.env.BUCKET_URL || "https://all-user-version-4.s3.us-east-1.amazonaws.com/";

const Quotation = () => {
  console.log('[Quotation] Component initializing');

  // Refs for preventing double exports and handling unmounts
  const isMounted = useRef(true);
  const exportInProgress = useRef(false);
  const initialRender = useRef(true);

  // State Management
  const [quotationData, setQuotationData] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pdfGenerating, setPdfGenerating] = useState(false);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 576px)" });

  // Cleanup on unmount
  useEffect(() => {
    // Skip on initial render
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    return () => {
      console.log('[Quotation] Component unmounting, cleaning up');
      isMounted.current = false;
      exportInProgress.current = false;
    };
  }, []);

  // Ensure number type conversion with validation
  const ensureNumber = useCallback((value) => {
    if (value === null || value === undefined) return 0;
    if (typeof value === 'number') return value;
    if (typeof value === 'string') {
      const parsed = parseFloat(value);
      return isNaN(parsed) ? 0 : parsed;
    }
    return 0;
  }, []);

  // Number to Words Conversion with caching
  const numberToEnglishSentence = useCallback((number) => {
    console.log('[numberToEnglish] Converting:', number);
    const numbersToWordsLessThanTwenty = [
      "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight",
      "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen",
      "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];
    
    const multiplesOfTen = [
      "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", 
      "Eighty", "Ninety"
    ];

    const convertToWords = (num) => {
      if (num === 0) return "Zero";
      if (num < 20) return numbersToWordsLessThanTwenty[num];
      
      if (num < 100) {
        return multiplesOfTen[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + convertToWords(num % 10) : "");
      }
      
      if (num < 1000) {
        return numbersToWordsLessThanTwenty[Math.floor(num / 100)] +
          " Hundred" +
          (num % 100 !== 0 ? " and " + convertToWords(num % 100) : "");
      }
      
      if (num < 100000) {
        return convertToWords(Math.floor(num / 1000)) +
          " Thousand" +
          (num % 1000 !== 0 ? " " + convertToWords(num % 1000) : "");
      }
      
      return convertToWords(Math.floor(num / 100000)) +
        " Lakh" +
        (num % 100000 !== 0 ? " " + convertToWords(num % 100000) : "");
    };

    const result = convertToWords(Math.abs(Math.floor(number)));
    console.log('[numberToEnglish] Result:', result);
    return result;
  }, []);

  // Process all numeric fields in quotation data
  const processQuotationData = useCallback((data) => {
    console.log('[processQuotationData] Starting data processing');
    
    try {
      // Process products
      const processedProducts = data.products?.map(product => {
        console.log('[processQuotationData] Processing product:', product);
        return {
          ...product,
          unitPrice: ensureNumber(product.unitPrice),
          quantity: ensureNumber(product.quantity)
        };
      }) || [];

      // Process discount
      const processedDiscount = data.discount ? {
        ...data.discount,
        amount: ensureNumber(data.discount.amount),
        rate: ensureNumber(data.discount.rate)
      } : null;

      // Process taxes
      const processedTaxes = data.taxes?.map(tax => ({
        ...tax,
        amount: ensureNumber(tax.amount),
        rate: ensureNumber(tax.rate)
      })) || [];

      // Process additional charges
      const processedCharges = data.additionalCharges?.map(charge => ({
        ...charge,
        amount: ensureNumber(charge.amount),
        rate: ensureNumber(charge.rate)
      })) || [];

      const processedData = {
        ...data,
        products: processedProducts,
        discount: processedDiscount,
        taxes: processedTaxes,
        additionalCharges: processedCharges,
        total: ensureNumber(data.total),
        subTotal: ensureNumber(data.subTotal),
        receivedAmount: ensureNumber(data.receivedAmount)
      };

      console.log('[processQuotationData] Processed data:', processedData);
      return processedData;
    } catch (error) {
      console.error('[processQuotationData] Error:', error);
      throw new Error('Failed to process quotation data');
    }
  }, [ensureNumber]);

  // Utility Functions
  const getCompleteImageUrl = useCallback((iconUrl) => {
    if (!iconUrl) return null;
    try {
      return iconUrl.startsWith('http') ? iconUrl : `${BUCKET_URL}${iconUrl}`;
    } catch (error) {
      console.error('[getCompleteImageUrl] Error:', error);
      return null;
    }
  }, []);

  const formatWithCommas = useCallback((value) => {
    const numValue = ensureNumber(value);
    return new Intl.NumberFormat('en-IN').format(numValue);
  }, [ensureNumber]);

  const createDate = useCallback((date) => {
    if (!date) return "";
    try {
      const quotDate = new Date(date);
      return quotDate.toLocaleDateString('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (error) {
      console.error('[createDate] Error:', error);
      return "";
    }
  }, []);

  const decimalToEnglish = useCallback((decimalNumber) => {
    if (!decimalNumber) return "";
    try {
      const [integerPart, decimalPart] = decimalNumber.toString().split(".");
      const integerWords = numberToEnglishSentence(parseInt(integerPart));
      const decimalWords = decimalPart ? 
        numberToEnglishSentence(parseInt(decimalPart)) : "";
      return decimalWords ? 
        `${integerWords} decimal ${decimalWords}` : integerWords;
    } catch (error) {
      console.error('[decimalToEnglish] Error:', error);
      return "";
    }
  }, [numberToEnglishSentence]);

  // PDF Generation Methods
  const generatePdf = useCallback(async (html) => {
    if (!html) return;
    try {
        return await QuotationPdf.exportPDF(html, quotationData);
    } catch (error) {
        console.error('PDF generation failed:', error);
        throw error;
    }
}, [quotationData]);

  const exportPDF = useCallback(async (html) => {
    if (exportInProgress.current || !isMounted.current) {
        console.log('Export already in progress or component unmounted');
        return;
    }

    exportInProgress.current = true;
    setPdfGenerating(true);

    try {
        await QuotationPdf.exportPDF(html, quotationData);
        console.log('Quotation PDF generated successfully');
    } catch (error) {
        console.error('Quotation PDF generation failed:', error);
        if (isMounted.current) {
            setError('Failed to export PDF');
        }
    } finally {
        if (isMounted.current) {
            setPdfGenerating(false);
        }
        exportInProgress.current = false;
    }
}, [quotationData]);

  // Data Fetching
  useEffect(() => {
    const fetchQuotationData = async () => {
      console.log('[fetchQuotationData] Starting fetch');
      setIsLoading(true);
      
      try {
        const url = new URL(window.location.href);
        const id = url.pathname.split("/")[2];
        const token = url.searchParams.get("token");
        
        const response = await axios.get(
          `https://mapi2.3sigmacrm.com/api/v2/quotation/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        if (!isMounted.current) return;

        const processedData = processQuotationData(response.data.data);
        setQuotationData(processedData);
        setCurrencySymbol(
          processedData.products[0]?.currency === "₹" 
            ? "Rs " 
            : processedData.products[0]?.currency || "Rs "
        );
      } catch (error) {
        console.error('[fetchQuotationData] Error:', error);
        if (isMounted.current) {
          setError('Failed to fetch quotation data');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchQuotationData();
  }, [processQuotationData]);

  const utils = {
    getCompleteImageUrl,
    createDate,
    formatWithCommas,
    decimalToEnglish,
    generatePdf,
    exportPDF
  };

  if (isLoading) {
    return <div className="loading-container">Loading quotation data...</div>;
  }

  if (error) {
    return <div className="error-container">Error: {error}</div>;
  }

  if (isLoading) {
    return <div className="loading-container">Loading...</div>;
  }
  
  if (error) {
    return <div className="error-container">{error}</div>;
  }
  
  if (!quotationData) {
    return null;
  }
  
  // Safe console log after the null check
  console.log('[Quotation] Rendering with data:', {
    hasData: !!quotationData._id,
    productsCount: quotationData?.products?.length || 0,
    pdfGenerating
  });
  
  return (
    <div>
      <QuotationPdfContainer
        onExport={(element) => QuotationPdf.exportPDF(element, quotationData)}
        quotationData={quotationData}
      >
        <QuotationView
          quotationData={quotationData}
          currencySymbol={currencySymbol}
          utils={utils}
        />
      </QuotationPdfContainer>
    </div>
  );
};

export default Quotation;