import React, { useEffect, useRef, useState } from 'react';

const OrderPdfContainer = ({ children, onExport, orderData }) => {
    const containerRef = useRef(null);
    const exportAttempted = useRef(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (orderData?._id) {
            exportAttempted.current = false;
            setIsReady(false);
            
            const timer = setTimeout(() => {
                setIsReady(true);
            }, 2000); // Increased timeout to ensure content is rendered

            return () => clearTimeout(timer);
        }
    }, [orderData]);

    useEffect(() => {
        if (!isReady || !orderData?._id || exportAttempted.current) {
            return;
        }

        const initiateExport = async () => {
            try {
                const content = containerRef.current?.querySelector('.pdf-content');
                
                console.log('PDF Export Check:', {
                    contentExists: !!content,
                    contentHTML: content?.innerHTML,
                    childrenCount: content?.childNodes?.length,
                });

                if (!content) {
                    console.error('PDF content element not found');
                    return;
                }

                if (!content.innerHTML || content.innerHTML.trim() === '') {
                    console.error('PDF content is empty');
                    return;
                }

                // Set basic styles for PDF generation
                content.style.background = 'white';
                content.style.minHeight = '297mm';  // A4 height
                content.style.width = '210mm';      // A4 width
                content.style.margin = '0 auto';
                content.style.padding = '20mm';
                
                console.log('Initiating PDF export with content');
                exportAttempted.current = true;
                await onExport(content);
                
            } catch (error) {
                console.error('PDF export failed:', error);
                exportAttempted.current = false;
            }
        };

        initiateExport();
    }, [orderData, onExport, isReady]);

    return (
        <div style={{ background: 'white', minHeight: '100vh' }}>
            <div ref={containerRef} style={{ width: '100%', maxWidth: '210mm', margin: '0 auto' }}>
                <div className="pdf-content" style={{ background: 'white', padding: '20px' }}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default OrderPdfContainer;