export const GoogleClientService = {
    "web": {
      "client_id": "322938904388-p7l4fso2n9g93n8irg3c9get69rn3vk4.apps.googleusercontent.com",
      "project_id": "sigma-8207c",
      "auth_uri": "https://accounts.google.com/o/oauth2/auth",
      "token_uri": "https://oauth2.googleapis.com/token",
      "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
      "client_secret": "2QJ4n_cwXzXDxqKY30MmcOgI",
      "redirect_uris": [
        "https://integration.3sigmacrm.com/integration/google_calendar/callback",
        "https://integration.3sigmacrm.com/integration/gmail/callback",
        "https://organic-computing-machine-v5q64j5vg9qcpx94-3000.app.github.dev/integration/google_calendar/callback",
        "https://organic-computing-machine-v5q64j5vg9qcpx94-3000.app.github.dev/integration/gmail/callback",
        "https://d1udxwejr0wjya.cloudfront.net/integration/google_calendar/callback",
        "https://d1udxwejr0wjya.cloudfront.net/integration/gmail/callback",
        "https://web.3sigmacrm.com/integration/google_calendar/callback",
        "https://web.3sigmacrm.com/integration/gmail/callback"
      ],
      "javascript_origins": [
        "https://integration.3sigmacrm.com",
        "https://organic-computing-machine-v5q64j5vg9qcpx94-3000.app.github.dev",
        "https://d1udxwejr0wjya.cloudfront.net",
        "https://web.3sigmacrm.com"
      ]
    }
  };