import React, { useEffect, useState, useMemo } from "react";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import Timer from "../../Components/timer";
import { generateOtp, signIn } from "./authServices";
import { useCountdown } from "../../Components/countdown";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const OtpVerification = () => {
    const [otp, setOTP] = useState("");
    const [verifying_otp, setVerifyOtp] = useState(false);
    const [otp_verify_error, setOtpVerifyError] = useState(false);
    const currentTime = new Date().getTime();
    const timeAfterOneSecond = currentTime + 1 * 60 * 1000;
    const [minutes, seconds] = useCountdown(timeAfterOneSecond);
    const [flag, setFlag] = useState(false);
    const history = useHistory();

    useMemo(() => {
        if (minutes + seconds <= 0) {
            setFlag(true);
        }
    }, [minutes, seconds]);

    const handleClick = async () => {
        var pattern = /^\d{6}$/;
        if (!pattern.test(otp)) {
            setOtpVerifyError(true);
        } else {
            setVerifyOtp(true);
            let data = {
                loginType: "phone",
                loggedFrom: "web",
                deviceToken: "abc",
                countryCode: localStorage.getItem("countryCode"),
                phone: localStorage.getItem("phone"),
                otp: otp,
            };

            try {
                if (otp.length === 6) {
                    const response = await signIn(data);
                    if (response && response.status) {
                        localStorage.setItem("auth_token", response?.data?.data?.token);
                        NotificationManager.success("Logged In Successfully!");
                        history.push("/delete-account")
                    }
                }
            } catch (err) {
                setVerifyOtp(false);
                NotificationManager.error("Error occured while logging in!");
            }
        }
    };

    const resendOTP = async (
        e
    ) => {
        e.preventDefault();
        const data = {
            phone: localStorage.getItem("phone"),
            countryCode: localStorage.getItem("countryCode"),
            otpType: "sms",
        };
        try {
            const response = await generateOtp(data);
            if (response && response.status) {
                NotificationManager.success("OTP Sent Successfully!");
            }
        } catch (err) {
            NotificationManager.error("Error occured while sending OTP!");
        }
    };

    const getOtpOverCall = async () => {
        const data = {
            phone: localStorage.getItem("phone"),
            countryCode: localStorage.getItem("countryCode"),
            otpType: "voice",
        };
        try {
            const response = await generateOtp(data);
            if (response && response.status) {
                // toast.success(response?.data?.message);
            }
        } catch (err) {
            // toast.error("Error occured while sending OTP!");
        }
    };

    const handleChange = (otp) => {
        setOTP(otp);
    };

    const editPhoneNumber = () => {
        history.push("user-login")
    };

    return (
        <div className="container">
            <div className="row justify-content-center otp_section">
                <div className="col-12 col-md-6 col-lg-5 col-xl-5 mt-5 login_card">
                    <div className="card" style={{ padding: "30px", margin: "3rem 0" }}>
                        <div className="d-flex flex-column align-items-center">
                            <h3 className="text-black Opt_h3">Enter 6 digit OTP code</h3>
                            <div className="Opt_h4 d-flex text-center">
                                <h4 className="align-self-center">
                                    Send to
                                    {localStorage.getItem("countryCode") != null &&
                                        " +" +
                                        localStorage.getItem("countryCode") +
                                        " " +
                                        localStorage.getItem("phone")}
                                    .
                                </h4>
                                <button
                                    onClick={() => editPhoneNumber()}
                                    className="btn btn-default"
                                >
                                    Change
                                </button>
                            </div>
                            <div className="otp-verification-header text-center mt-3">
                                <OtpInput
                                    value={otp}
                                    onChange={handleChange}
                                    numInputs={6}
                                    isInputNum
                                    separator={<span className="ms-2"> </span>}
                                    shouldAutoFocus={true}
                                    inputStyle={{
                                        width: "3rem",
                                        height: "3rem",
                                        borderTop: "0px",
                                        borderRight: "0px",
                                        borderLeft: "0px",
                                    }}
                                />
                                <button
                                    className="btn btn-dark login-btn w-100 mt-5"
                                    style={{ backgroundColor: "#3A4B86" }}
                                    onClick={handleClick}
                                >
                                    {verifying_otp ? "Verifying..." : "Verify OTP"}
                                </button>
                            </div>
                            {otp_verify_error ? (
                                <p className="text-danger mt-3">Invalid OTP!</p>
                            ) : (
                                false
                            )}
                            <div className="otp_footer mt-3">
                                <div className="d-flex justify-content-center">
                                </div>
                                <div className="d-flex justify-content-center otp-not-received">
                                    {flag ? (
                                        <>
                                            <p className="m-3 p_opt" onClick={(e) => resendOTP(e)}>
                                                Resend OTP
                                            </p>
                                            <p className="my-3">|</p>
                                            <p className="m-3 p_opt" onClick={() => getOtpOverCall()}>
                                                Get OTP over call
                                            </p>
                                        </>
                                    ) : (
                                        <p className="m-3 p_opt">
                                            <i className="bi bi-clock"></i> Resend another OTP in
                                            <p className="timer_sec">
                                                <Timer seconds={seconds} />
                                            </p>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};
export default OtpVerification;